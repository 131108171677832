import { useState, FormEvent, useEffect } from 'react';
import { TextField, Stack } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { CircularProgressButton } from './Form';

export default function EmailInputWithContinueButton({
  initialEmail,
  onEmailChange,
  onSubmit,
}: {
  initialEmail?: string;
  onEmailChange?: (email: string) => void;
  onSubmit: ({ email, event }: { email: string; event: FormEvent<HTMLFormElement> }) => void;
}) {
  const [email, setEmail] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    // The initialEmail may null at the first then it is populated a value,
    // thus we need to reset the email by the actual value
    setEmail(initialEmail || '');
  }, [initialEmail]);

  return (
    <Stack
      width="100%"
      component="form"
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        onSubmit({ email, event });
        setIsSubmitting(true);
      }}
      gap={tokens.spacingLg}
      alignItems="center"
    >
      <TextField
        fullWidth
        variant="outlined"
        size="medium"
        type="email"
        autoFocus
        label="Email"
        value={email}
        autoComplete="webauthn"
        onChange={(e) => {
          const email = e.target.value as string;
          setEmail(email);
          onEmailChange?.(email);
        }}
        sx={{
          marginTop: tokens.spacingLg,
        }}
        disabled={isSubmitting}
      />
      <CircularProgressButton
        fullWidth
        size="large"
        color="primary"
        type="submit"
        variant="contained"
        sx={{
          gap: tokens.spacingXs,
        }}
        disabled={!email.match(/^[a-zA-Z0-9_+-.]{1,64}@[a-zA-Z0-9-.]+\.[a-zA-Z]{2,63}$/)}
        isShowProgress={isSubmitting}
      >
        Continue
      </CircularProgressButton>
    </Stack>
  );
}
