import { IdentityType } from '../types';
import { alpha } from '@mui/material/styles';
import ProceedIcon from '@mui/icons-material/ChevronRightRounded';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import tokens from '@verifime/design-tokens';
import { useRouter } from 'next/router';

type ListItemButtonProps = Omit<Parameters<typeof ListItemButton>[0], 'children'>;

export type TIdentityTypeSelectionProps = {
  name: IdentityType | string;
  description?: string;
  onClick?: (type?: IdentityType | string) => void;
  path?: string;
  disabled?: boolean;
} & ListItemButtonProps &
  React.Attributes;

export default function ItemToSelect({
  name,
  description,
  onClick,
  path,
  disabled = false,
  ...otherProps
}: TIdentityTypeSelectionProps) {
  const router = useRouter();
  return (
    <ListItem disablePadding sx={{ maxWidth: tokens.sizeSm }}>
      <ListItemButton
        sx={{
          borderRadius: tokens.spacingXs,
          border: (theme) => `1px solid ${alpha(theme.palette.text.primary, 0.2)}`, // need border token!
        }}
        onClick={() => {
          if (path) {
            router.push(path);
          } else if (onClick) {
            onClick();
          }
        }}
        disabled={disabled}
        {...otherProps}
      >
        <ListItemText
          primary={name}
          secondary={description}
        />
        <ListItemIcon
          sx={{
            padding: tokens.spacingSm,
          }}
        >
          <ProceedIcon />
        </ListItemIcon>
      </ListItemButton>
    </ListItem>
  );
}
