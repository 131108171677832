import React, { useState } from 'react';
import { Typography, Stack, Box, Button, useTheme } from '@mui/material';
import { LoginCenterContainer, LoginContainer } from '../common/LoginContainer';
import Title from '../common/LoginTitle';
import tokens from '@verifime/design-tokens';
import IconButton from '@mui/material/IconButton';
import { default as BackIcon } from '@mui/icons-material/ArrowBackRounded';
import { SetupState } from '../../types';
import SecurityKeysSvg from '../images/SecurityKeys.svg';
import { FidoSetupErrorMessage } from '../common/LoginAlert';
import { LEARN_MORE_PASSKEY_URL, LoginMethod } from '@verifime/utils';
import { CircularProgressButton } from '../../Form';
import { IconLinkOpenNew } from '../../IconLinkOpenNew';
import QrcodeIcon from '../images/QrcodeIcon.svg';

export type TSetupPasskeyProps = {
  setSetupState: (setupState: SetupState) => void;
  setupComplete: (loginMethod: LoginMethod) => void;
  registerFIDO: () => Promise<void>;
};

export default function SetupPasskey({
  setSetupState,
  setupComplete,
  registerFIDO,
}: TSetupPasskeyProps) {
  const [setupError, setSetupError] = useState<Error>(undefined);
  const [isSettingUpPasskey, setSettingUpPasskey] = useState(false);
  const theme = useTheme();

  const handleSetupSecurityKey = async () => {
    setSetupError(undefined);
    setSettingUpPasskey(true);
    registerFIDO()
      .then(() => setupComplete(LoginMethod.Passkey))
      .catch((error) => {
        setSettingUpPasskey(false);
        setSetupError(error);
      });
  };

  return (
    <LoginContainer>
      <LoginCenterContainer gap={tokens.spacingBase} alignItems="start">
        <IconButton
          onClick={() => setSetupState(SetupState.FIDO_STEP1)}
          sx={{
            p: 0,
          }}
        >
          <BackIcon color="action" />
        </IconButton>
        <Stack alignItems="start" gap={tokens.spacingLg}>
          <Stack gap={tokens.spacing2xs}>
            <Title>Add your secure device</Title>
            <Typography variant="body2" color="textSecondary">
              Use your fingerprint, faceID or PIN to log into VerifiMe
            </Typography>
          </Stack>
          <Stack gap={tokens.spacingBase}>
            <SecurityKeysSvg />
            <Typography variant="body1">
              Your biometrics remain private on your device and are never shared with us.
            </Typography>
          </Stack>
          <Box sx={{ alignSelf: 'center' }}>
            <IconLinkOpenNew href={LEARN_MORE_PASSKEY_URL}>
              Learn more about passkeys
            </IconLinkOpenNew>
          </Box>

          {setupError && <FidoSetupErrorMessage error={setupError} />}

          <CircularProgressButton
            variant={setupError ? 'text' : 'contained'}
            onClick={handleSetupSecurityKey}
            isShowProgress={isSettingUpPasskey}
            disabled={isSettingUpPasskey}
            sx={{ width: '100%' }}
          >
            Add PASSKEY
          </CircularProgressButton>
          <Button
            fullWidth
            variant={setupError ? 'contained' : 'text'}
            startIcon={
              <QrcodeIcon
                fill={setupError ? theme.palette.primary.contrastText : theme.palette.primary.main}
              />
            }
            onClick={() => setSetupState(SetupState.TOTP)}
          >
            Try another way
          </Button>
        </Stack>
      </LoginCenterContainer>
    </LoginContainer>
  );
}
