import { createTheme } from '@mui/material/styles';
import { PaletteMode, ThemeProvider, useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import darkTheme from './darkTheme';
import lightTheme from './lightTheme';

const LOCAL_STORAGE_KEY_THEME = 'THEME';
const SUPPORTED_THEME = ['dark', 'light'];

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export type TThemeMode = PaletteMode;

export default function ToggleTheme({ children }: { children: ReactNode }) {
  const [mode, setMode] = useState<TThemeMode>('light');

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  useEffect(() => {
    const localStoredTheme = getLocalStoredTheme();
    if (localStoredTheme) {
      setMode(localStoredTheme);
    }
  }, []);

  useEffect(() => {
    const localStoredTheme = getLocalStoredTheme();
    // We only set system preference theme only local storaged theme does not exists
    if (localStoredTheme) {
      return;
    }
    setMode(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode]);

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const nextTheme = prevMode === 'light' ? 'dark' : 'light';
          localStorage.setItem(LOCAL_STORAGE_KEY_THEME, nextTheme);
          return nextTheme;
        });
      },
    }),
    [],
  );

  const theme = useMemo(() => createTheme(getModeTheme(mode)), [mode]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

function getModeTheme(mode: PaletteMode) {
  return mode === 'light' ? lightTheme : darkTheme;
}

function getLocalStoredTheme() {
  const localStoragedTheme = localStorage.getItem(LOCAL_STORAGE_KEY_THEME) || '';
  if (SUPPORTED_THEME.includes(localStoragedTheme)) {
    return localStoragedTheme as TThemeMode;
  }
  return null;
}
