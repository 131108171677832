import { Alert, Collapse, IconButton, Typography, useTheme, SxProps } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Image from 'next/image';
import { useState } from 'react';
import { CircleLabel, FlexBox } from '@verifime/components';
import ResponsiveBox from './ResponsiveBox';

import tokens from '@verifime/design-tokens';

type TInstructionProps = {
  title: string;
  body: string[] | string;
  footer?: string;
  className?: string;
  imageSrc?: string;
  imageAlt?: string;
  sx?: SxProps;
};

export default function Instruction({
  title,
  body,
  footer,
  className,
  imageSrc,
  imageAlt,
  sx,
}: TInstructionProps) {
  const [open, setOpen] = useState(true);
  const theme = useTheme();

  return (
    <Collapse in={open}>
      <Alert
        className={className}
        icon={false}
        variant="filled"
        action={
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <HighlightOffIcon fontSize="large" />
          </IconButton>
        }
        sx={{
          background: theme.palette.background.paper,
          borderRadius: tokens.spacingBase,
          color: theme.palette.text.primary,
          ...sx,
        }}
      >
        <ResponsiveBox responsiveOnWidth="md" verticalAlign="middle" responsiveAlign="center">
          {/* TODO: adjusting to proper size tokens */}
          {imageSrc && <Image src={imageSrc} width="120" height="120" alt={imageAlt || ''} />}
          <FlexBox direction="column">
            <Typography variant="body1">{title}</Typography>
            {Array.isArray(body) ? (
              body.map((content, index) => (
                <Typography
                  key={content}
                  variant="body1"
                  component="div"
                  sx={{ display: 'flex', gap: tokens.spacingBase, alignItems: 'center' }}
                >
                  <CircleLabel label={index + 1} />
                  <Typography sx={{ fontSize: tokens.fontSizeBase, lineHeight: '19px' }}>
                    {content}
                  </Typography>
                </Typography>
              ))
            ) : (
              <Typography
                variant="body1"
                component="div"
                sx={{ display: 'flex', gap: tokens.spacingXs, alignItems: 'center' }}
              >
                {body}
              </Typography>
            )}
            {footer && <Typography variant="subtitle1">{footer}</Typography>}
          </FlexBox>
        </ResponsiveBox>
      </Alert>
    </Collapse>
  );
}
