/**
 * forked from https://github.com/jonatanklosko/material-ui-confirm
 */
import * as React from 'react';
import { DialogProps } from '@mui/material/Dialog';
import { DialogActionsProps } from '@mui/material/DialogActions';
import { DialogTitleProps } from '@mui/material/DialogTitle';
import { DialogContentProps } from '@mui/material/DialogContent';
import { ButtonProps } from '@mui/material/Button';
import { TextFieldProps } from '@mui/material/TextField';

export interface ConfirmOptions {
  title?: React.ReactNode;
  titleProps?: DialogTitleProps;
  description?: React.ReactNode;
  content?: React.ReactNode | null;
  contentProps?: DialogContentProps;
  confirmationText?: React.ReactNode;
  cancellationText?: React.ReactNode;
  dialogProps?: Omit<DialogProps, 'open'>;
  dialogActionsProps?: DialogActionsProps;
  confirmationButtonProps?: ButtonProps;
  cancellationButtonProps?: ButtonProps;
  allowClose?: boolean;
  hideCancelButton?: boolean;
  buttonOrder?: ('confirm' | 'cancel')[];

  inputbox?: boolean;
  defaultInputboxValue?: string;
  inputboxTextFieldProps?: TextFieldProps;
  inputboxValueRequired?: boolean;
}

export interface ConfirmProviderProps {
  children: React.ReactNode;
  defaultOptions?: ConfirmOptions;
}

export { default as ConfirmProvider } from './ConfirmProvider';
export { default as useConfirm } from './useConfirm';
