import { Box, Link, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { stringUtils } from '@verifime/utils';
import { useState } from 'react';
import { DocumentNotFound, DocumentNotSupported } from '..';
import { ColoredIcon } from '../ColoredIconAndChip';
import { TDocumentUrl } from './types';

export default function ImageThumbnail({ url, filename }: Readonly<TDocumentUrl>) {
  const [isImageBroken, setIsImageBroken] = useState(false);

  if (!url) {
    return <DocumentNotFound />;
  }

  return (
    <Stack gap={tokens.spacingBase}>
      {isImageBroken ? (
        <DocumentNotSupported />
      ) : (
        /** We have several environment,
         * as discussed we don't wanna config those hostname in next.config.js,
         * thus we don't use next/Image use native img instead */
        <img
          style={{ borderRadius: tokens.borderRadiusSm, aspectRatio: '25/16' }}
          alt={filename}
          src={url}
          onError={() => setIsImageBroken(true)}
        />
      )}

      <Link href={url} target="_blank" sx={{ display: 'flex', gap: tokens.spacing2xs }}>
        <Typography>{stringUtils.ellipsis(filename || url, 'middle')}</Typography>
        <Box>
          <ColoredIcon iconName="openInNewRounded" />
        </Box>
      </Link>
    </Stack>
  );
}
