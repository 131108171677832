import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectChangeEvent,
  SelectProps,
  FormControlProps,
} from '@mui/material';
import { ReactNode } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { formFieldLabel } from './utils';

type TValue = string | string[] | undefined;

type TReactHookFormSelectProps = {
  name: string;
  label: string;
  control: Control<FieldValues, TValue>;
  defaultValue?: TValue;
  required?: boolean;
  onOptionChange?: (event: SelectChangeEvent) => void;
  dataCy?: string;
  children: ReactNode;
  // see on why https://github.com/mui/material-ui/issues/18580
  // Select component does not support all margin property like other components,
  // it must be put into FormControl component to support margin property.
  margin?: FormControlProps['margin'];
  size?: SelectProps<TValue>['size'];
} & Omit<SelectProps<TValue>, 'margin'>;

export default function FormSelect({
  name,
  label,
  control,
  onOptionChange,
  required = false,
  defaultValue = '',
  dataCy,
  margin = 'normal',
  size = 'medium',
  children,
  sx,
  ...selectProps
}: TReactHookFormSelectProps) {
  const requiredLabel = formFieldLabel(label, required);
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        return (
          <>
            <FormControl
              sx={{ flex: '1', ...sx }}
              fullWidth
              error={!!fieldState.error?.message}
              margin={margin}
            >
              <InputLabel>{requiredLabel}</InputLabel>
              <Select
                {...selectProps}
                {...field}
                size={size}
                onChange={(event: SelectChangeEvent) => {
                  field.onChange(event);

                  // The custom onOptionChange must be called last to allow field level onChange event propagate first.
                  onOptionChange?.(event);
                }}
                label={requiredLabel}
                data-cy={dataCy}
                MenuProps={{
                  id: dataCy,
                }}
              >
                {children}
              </Select>
              <FormHelperText>{fieldState.error?.message}</FormHelperText>
            </FormControl>
          </>
        );
      }}
    />
  );
}
