import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { ContentDetailsDisplay, ColoredIcon, SetupAuthenticatorApp } from '@verifime/components';
import { useLocalUserCache } from './hooks/usePasswordless';
import { otpAssociateCredential } from '../otp';
import { default as tokens } from '@verifime/design-tokens';
import { determineOtpIssuer } from '../utils';

export default function SetupAuthenticatorAppDrawer({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { currentUser } = useLocalUserCache();

  function registerOTP(secret: string | (() => string | Promise<string>)): Promise<string> {
    return otpAssociateCredential({ secret });
  }

  return (
    <ContentDetailsDisplay
      isOpen={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: tokens.sizeSm,
        },
      }}
    >
      <Stack gap={tokens.spacingXs}>
        <Box alignSelf="end">
          <IconButton aria-label="close" onClick={onClose}>
            <ColoredIcon iconName="closeRounded" fontSize="medium" />
          </IconButton>
        </Box>
        <SetupAuthenticatorApp
          otpIssuer={determineOtpIssuer()}
          otpUserDisplayName={currentUser?.email}
          registerOTP={registerOTP}
          setupComplete={onClose}
          displayAddPasskey={false}
          displayBackOnEntry={false}
        />
      </Stack>
    </ContentDetailsDisplay>
  );
}
