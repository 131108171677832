import { css } from 'styled-components';

const NavIcon = ({ fillcolor }: { fillcolor: string }) => css`
  path {
    fill: ${fillcolor};
    stroke: ${fillcolor};
  }
`;

export default NavIcon;
