export type TFeatureToggle = {
  isEnabledInviteCustomers: boolean;
  isEnabledHidePasskeySetupOption: boolean;
  isEnabledSupportingDocuments: boolean;
  isEnabledSupportingDocumentSubmissionList: boolean;
  isEnabledFaceCheckInIndividualWizard: boolean;
};

export enum FeatureToggle {
  On = 'on',
  Off = 'off',
}

export function useFeatureToggle(): TFeatureToggle {
  return {
    isEnabledInviteCustomers:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_INVITE_CUSTOMERS === FeatureToggle.On,
    isEnabledHidePasskeySetupOption:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_HIDE_PASSKEY_SETUP_OPTION === FeatureToggle.On,
    isEnabledSupportingDocuments:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_SUPPORTING_DOCUMENTS === FeatureToggle.On,
    isEnabledSupportingDocumentSubmissionList:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_SUPPORTING_DOCUMENTS_SUBMISSION_LIST ===
      FeatureToggle.On,
    isEnabledFaceCheckInIndividualWizard:
      process.env.NEXT_PUBLIC_FEATURE_TOGGLE_FACE_CHECK_IN_INDIVIDUAL_WIZARD_STEP ===
      FeatureToggle.On,
  };
}
