import { TSupportedOrganisationIdentityTypeCode } from '@verifime/utils';
import useAddEntity from './useAddEntity';
import { wizardOrganisationFormFieldsAndRules } from './utils';
import { TFormFieldsAndRules_New } from '../Form';

type TUseAddEntity = Parameters<typeof useAddEntity>[0];

export default function useAddOrganisationEntity<
  OrganisationTypeCode extends TSupportedOrganisationIdentityTypeCode,
>({
  organisationTypeCode,
  extraFormFieldsAndRules = {},
  ...props
}: {
  organisationTypeCode: OrganisationTypeCode;
  extraFormFieldsAndRules?: TFormFieldsAndRules_New;
} & Omit<TUseAddEntity, 'initialFormFieldsAndRules' | 'initialAddresses'>) {
  return useAddEntity({
    initialFormFieldsAndRules: {
      ...wizardOrganisationFormFieldsAndRules[organisationTypeCode].basic,
      ...extraFormFieldsAndRules,
    },
    initialAddresses: wizardOrganisationFormFieldsAndRules[organisationTypeCode].addresses,
    ...props,
  });
}
