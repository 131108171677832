import 'keen-slider/keen-slider.min.css';
import {
  KeenSliderHooks,
  KeenSliderInstance,
  KeenSliderOptions,
  SliderInstance,
  useKeenSlider,
} from 'keen-slider/react';
import { CSSProperties, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import throttle from 'lodash/throttle';
import { ColoredIcon } from './ColoredIconAndChip';
import { TObject } from '@verifime/utils';

const Arrow = ({
  disabled,
  left,
  onClick,
  leftArrowStyle,
  rightArrowStyle,
}: {
  disabled?: boolean;
  left?: boolean;
  onClick: (e: any) => void;
  leftArrowStyle?: CSSProperties;
  rightArrowStyle?: CSSProperties;
}) => {
  const theme = useTheme();

  const arrowLeftStyle = { left: 0, ...leftArrowStyle };
  const arrowRightStyle = { left: 'auto', right: 0, ...rightArrowStyle };
  const arrowStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: 4,
    ...(left ? arrowLeftStyle : arrowRightStyle),
  };
  return (
    <>
      {left && (
        <IconButton sx={{ ...arrowStyle }} disabled={disabled} onClick={onClick}>
          <ColoredIcon iconName="arrowLeftRounded" colorName="actionActive" isCompact={true} />
        </IconButton>
      )}
      {!left && (
        <IconButton sx={{ ...arrowStyle }} disabled={disabled} onClick={onClick}>
          <ColoredIcon iconName="arrowRightRounded" colorName="actionActive" isCompact={true} />
        </IconButton>
      )}
    </>
  );
};

const ResizePluginWithSetLoop = (
  slider: SliderInstance<
    KeenSliderOptions<{}, {}, KeenSliderHooks>,
    KeenSliderInstance<{}, {}, KeenSliderHooks>,
    KeenSliderHooks
  >,
  setLoop: Dispatch<SetStateAction<boolean | { min?: number; max?: number }>>,
) => {
  let initialSliderLoop:
    | boolean
    | null
    | {
        min?: number;
        max?: number;
      } = null;
  if (!initialSliderLoop) {
    initialSliderLoop = slider.options.loop;
  }

  const updateSlides = () => {
    const slides = (slider.options.slides as TObject) || {};
    const spacing = slides.sapcing || 0;
    const defaultOrigin = slides.origin || 'auto';
    const containerWidth = slider.container.offsetWidth;
    const itemsWidth =
      slider.slides.length * (slider.slides[slider.track.details.rel].offsetWidth + spacing) -
      spacing;
    const origin =
      defaultOrigin === 'center' && itemsWidth < containerWidth ? 'auto' : defaultOrigin;
    const loop = itemsWidth < containerWidth ? false : initialSliderLoop;

    setLoop(loop);

    slider.update({
      ...slider.options,
      loop,
      slides: { ...(slides || {}), origin },
    });
  };

  const throttleUpdateSlides = throttle(updateSlides, 500);

  const observer = new ResizeObserver(throttleUpdateSlides);

  slider.on('created', () => {
    observer.observe(slider.container);
  });
  slider.on('destroyed', () => {
    observer.unobserve(slider.container);
  });
};

/**
 *
 * Each child needs a property `className="keen-slider__slide"` otherwise gets error
 */
export default function Carousel({
  children,
  showArrow = 'never',
  keenSliderOptions,
  leftArrowStyle,
  rightArrowStyle,
}: {
  children: ReactNode;
  showArrow?: 'always' | 'never' | 'hide-on-edge';
  keenSliderOptions?: KeenSliderOptions;
  leftArrowStyle?: CSSProperties;
  rightArrowStyle?: CSSProperties;
}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [loop, setLoop] = useState(keenSliderOptions?.loop);
  const [sliderRef, instanceRef] = useKeenSlider(
    {
      initial: 0,
      slides: { perView: 'auto', spacing: 12 },
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created(slider) {
        setCurrentSlide(slider.track.details.rel);
        setLoaded(true);
      },
      ...keenSliderOptions,
    },
    [(slider) => ResizePluginWithSetLoop(slider, setLoop)],
  );

  const isLoaded = loaded && instanceRef.current;
  const isAlwaysShow = 'always' === showArrow;
  const isNotOnLeftEdge = 'hide-on-edge' === showArrow && currentSlide !== 0;
  const isNotOnRightEdge =
    'hide-on-edge' === showArrow && currentSlide !== instanceRef.current?.track?.details?.maxIdx;

  const isShowLeftArrow = isLoaded && (isAlwaysShow || loop || isNotOnLeftEdge);
  const isShowRightArrow = isLoaded && (isAlwaysShow || loop || isNotOnRightEdge);

  return (
    <Box sx={{ position: 'relative' }}>
      <Box ref={sliderRef} className="keen-slider">
        {children}
      </Box>
      {(isShowLeftArrow || isShowRightArrow) && (
        <Box>
          <>
            {isShowLeftArrow && (
              <Arrow
                left
                onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
                disabled={!loop && currentSlide === 0}
                leftArrowStyle={leftArrowStyle}
              />
            )}
            {isShowRightArrow && (
              <Arrow
                onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
                disabled={
                  !loop && currentSlide === instanceRef.current.track.details.slides.length - 1
                }
                rightArrowStyle={rightArrowStyle}
              />
            )}
          </>
        </Box>
      )}
    </Box>
  );
}
