import { Paper, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import React from 'react';
import CheckErrorIcon from './error.svg';

export default function CheckError() {
  return (
    <Paper>
      <Stack
        paddingX={tokens.spacingBase}
        paddingY={tokens.spacingLg}
        borderRadius={tokens.borderRadiusSm}
        gap={tokens.spacingBase}
        alignItems="center"
      >
        <CheckErrorIcon />
        <Typography variant="h6">Face check is unavailable at the moment</Typography>
        <Typography variant="body2" color="textSecondary">
          We are unable to complete a face check at the moment.
          <br />
          Please try again later.
        </Typography>
      </Stack>
    </Paper>
  );
}
