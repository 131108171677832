import { RenderType, TFormFieldsAndRules_New, TInformationDisplayRows, TInformationItemDisplay } from '@verifime/components';
import { AttributeMapping, formatToDDMMMYYYY, getSupportDocumentAttributeMapping, validationRules } from '@verifime/utils';
import {
  apiSchemas,
  TSupportDocumentSubType,
} from '@verifime/api-definition';
import { subDays } from 'date-fns';
import { TNameValueDisplayType } from '../NameValueDisplay';

export const SupportDocumentTypeOptions:
  { [subtype in TSupportDocumentSubType]: { type: string; description: string } } = {
  [apiSchemas.SupportDocumentSubType.Enum.WholesaleCertificate]: {
    type: "Wholesale Certificate",
    description: "Invest in financial products as a certified wholesale investor",
  },
  [apiSchemas.SupportDocumentSubType.Enum.CertificateOfTitle]: {
    type: "Certificate of Title",
    description: "Prove ownership of your property",
  },
  [apiSchemas.SupportDocumentSubType.Enum.ValuationNotice]: {
    type: "Valuation Notice",
    description: "Prove ownership of your property",
  },
  [apiSchemas.SupportDocumentSubType.Enum.RatesNotice]: {
    type: "Rates Notice",
    description: "Prove ownership of your property",
  },
  [apiSchemas.SupportDocumentSubType.Enum.NationalVendorDeclarationForLivestock]: {
    type: "National Vendor Declaration for Livestock",
    description: "",
  },
  [apiSchemas.SupportDocumentSubType.Enum.Other]: {
    type: "Other Documents",
    description: "",
  },
};

enum FormatType {
  Date = 'Date',
  Array = 'Array',
  Money = 'Money',
  TextArea = "TextArea",
}

export function getKeyAttributeLabel(
  subType: string,
  keyAttribute: string
): string {
  return getSupportDocumentAttributeMapping(subType)[keyAttribute]?.label;
}

function formatMoney(value: number): string {
  return new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(value);
}

function formatArray(value: string[]): string {
  return value.join(', ');
}

const maxDateIssued = new Date();

const { REQUIRED_STRING_RULES, requiredDateRule } = validationRules;

const rulesMapper: { [key in FormatType]: any } = {
  [FormatType.Date]: requiredDateRule({ max: maxDateIssued }),
  [FormatType.Array]: REQUIRED_STRING_RULES,
  [FormatType.Money]: REQUIRED_STRING_RULES,
  [FormatType.TextArea]: REQUIRED_STRING_RULES,
};

function createAttributeMapping(attribute: string, attr: AttributeMapping, copyable: boolean): TInformationItemDisplay<string> {
  const displayType: TNameValueDisplayType = copyable ? "vertical" : "horizontal";

  return {
    label: attr.label,
    fieldName: attribute,
    copyable: copyable,
    displayType: displayType,
    ...(attr.formatType === FormatType.Date && {
      valueFormatter: (value: string) => formatToDDMMMYYYY(value),
    }),
    ...(attr.formatType === FormatType.Money && {
      valueFormatter: (value: number) => formatMoney(value),
    }),
    ...(attr.formatType === FormatType.Array && {
      valueFormatter: (value: string[]) => formatArray(value),
    }),
  };
}

function createFieldMapping(attribute: string, attr: AttributeMapping) {
  let renderType: RenderType;
  switch (attr.formatType) {
    case FormatType.Date:
      renderType = RenderType.Date;
      break;
    case FormatType.Money:
      renderType = RenderType.Number;
      break;
    default:
      renderType = RenderType.Text;
  }

  return {
    label: attr.label,
    fieldName: attribute,
    renderType: renderType,
    rules: attr.formatType ? rulesMapper[attr.formatType] : REQUIRED_STRING_RULES,
    ...(attr.formatType === FormatType.Date && { maxDate: maxDateIssued }),
    ...(attr.formatType === FormatType.TextArea && { multiline: true }),
    ...(attr.formatType === FormatType.Money && { startAdornment: "$" }),
  };
}

export function generateDisplayRowsAndFieldsAndRules({
  subType, 
  displayKeyAttribute, 
  keyAttribute, 
  copyable = false, 
  numberOfItemsPerRow = 2
} : {
  subType: string;
  displayKeyAttribute: boolean;
  keyAttribute: string;
  copyable?: boolean;
  numberOfItemsPerRow?: number;
}) : {
  displayRows: TInformationDisplayRows<string | number>,
  fieldsAndRules: TFormFieldsAndRules_New<string>
} {
  const attributesMapping = getSupportDocumentAttributeMapping(subType);

  const displayRows: TInformationDisplayRows<string> = {};
  const fieldsAndRules: TFormFieldsAndRules_New<any> = {};

  const attributes = Object.keys(attributesMapping);
  let lastFieldIsTextArea = false;

  // Find the index of the key attribute and remove it from the attributes list.
  const keyAttributeIndex = attributes.indexOf(keyAttribute);
  if (keyAttributeIndex !== -1) {
    attributes.splice(keyAttributeIndex, 1);
  }

  let rowCounter = 1;

  // If the key attribute should be displayed and exists in the mappings, add it to the first row.
  if (displayKeyAttribute && keyAttribute && attributesMapping[keyAttribute]) {
    const keyAttributeMapping = attributesMapping[keyAttribute];
    displayRows[`row${rowCounter}`] = [
      createAttributeMapping(keyAttribute, keyAttributeMapping, copyable)
    ];

    fieldsAndRules[`row${rowCounter}`] = [
      createFieldMapping(keyAttribute, keyAttributeMapping)
    ];

    rowCounter++;
  }

  // Iterate over the remaining attributes in chunks of numberOfItemsPerRow to add them to display rows.
  for (let i = 0; i < attributes.length; i += numberOfItemsPerRow) {
    const rowKey = `row${rowCounter}`;
    displayRows[rowKey] = attributes.slice(i, i + numberOfItemsPerRow).map(attribute =>
      createAttributeMapping(attribute, attributesMapping[attribute], copyable)
    );

    const fieldMappings = attributes.slice(i, i + numberOfItemsPerRow).map(attribute =>
      createFieldMapping(attribute, attributesMapping[attribute])
    );

    // Check if the last field in the current chunk is a TextArea
    lastFieldIsTextArea = attributesMapping[attributes[i + numberOfItemsPerRow - 1]]?.formatType === FormatType.TextArea;

    if (lastFieldIsTextArea) {
      // Move the last field (TextArea) to a new row
      fieldsAndRules[rowKey] = fieldMappings.slice(0, -1);
      fieldsAndRules[`row${rowCounter + 1}`] = [fieldMappings[fieldMappings.length - 1]];
    } else {
      fieldsAndRules[rowKey] = fieldMappings;
    }
    rowCounter++;
  }
  
  return { displayRows, fieldsAndRules };
}