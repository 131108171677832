import { Stack } from '@mui/material';
import { TCustomerResponse } from '@verifime/api-definition';
import {
  ADDRESS_PREFIX_SEPARATOR,
  SERVER_ERRORS,
  SupportedCountryCode,
  TAddress,
  TCustomer,
  TObject,
  basicFieldsPrefix,
  stringUtils,
} from '@verifime/utils';
import isEqual from 'lodash/isEqual';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import {
  ErrorScope,
  GenerateFormFields_New,
  TAddressProps,
  TAddressWithCountrySelectProps,
  TErrors,
  TFormFieldsAndRules,
  TFormFieldsAndRules_New,
  generateNonFieldErrors,
  getFieldsNamesFromFieldsAndRules,
  getFieldsNamesFromFieldsAndRules_New,
  getFieldsRules,
  getFieldsRules_New,
  showFieldsErrors,
  useCustomForm,
} from '../Form';
import {
  AVAILABLE_COUNTRIES,
  AddressFieldsAndRulesName,
  COUNTRY_ADDRESS_FIELDS_AND_RULES,
} from './utils';

export type TAddEntityAddress = {
  renderType: 'AddressOnly' | 'AddressWithCountrySelect';
  fieldsAndRulesName: AddressFieldsAndRulesName;
  fieldsPrefix: string;
  label?: string;
  inputLabel?: string;
  inputPlaceholder?: string;
  sameAsAddress?: { addressFieldsAndRulesName: AddressFieldsAndRulesName; label: string };
};
export type TAddEntityAddresses = TAddEntityAddress[];

export default function useAddEntity({
  initialFormFieldsAndRules,
  submitApiRequest,
  retrieveCustomerApiRequest,
  customerId,
  initialAddresses = [],
  AddressComponent,
  AddressWithCountrySelectComponent,
  fieldsNotChangedCallback,
  onSubmitComplete,
  onRetrieveComplete,
}: {
  initialFormFieldsAndRules: TFormFieldsAndRules_New;
  submitApiRequest: (
    formVerificationInfo: Record<string, any>,
    defaultCustomer?: TCustomer,
  ) => Promise<TCustomerResponse>;
  retrieveCustomerApiRequest?: (customerId: string) => Promise<TCustomer>;
  customerId?: string;
  initialAddresses?: TAddEntityAddresses;
  AddressComponent?: React.FC<Omit<TAddressProps, 'googleMapsApiKey'>>;
  AddressWithCountrySelectComponent?: React.FC<
    Omit<TAddressWithCountrySelectProps, 'googleMapsApiKey'>
  >;
  fieldsNotChangedCallback?: () => void;
  onSubmitComplete?: (customer: TCustomer, reason?: any, formData?: TObject) => void;
  onRetrieveComplete?: (customer: TCustomer, reason?: any) => void;
}) {
  const [nonFieldErrors, setNonFieldErrors] = useState<TErrors>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRetriving, setIsRetriving] = useState(false);
  const [defaultCustomer, setDefaultCustomer] = useState<TCustomer | null>(null);
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const [prevFormFieldsAndRules, setPrevFormFieldsAndRules] =
    useState<TFormFieldsAndRules_New | null>(null);
  const [prevAddresses, setPrevAddresses] = useState<TAddEntityAddresses | null>(null);
  const [prevSelectedCountryCode, setPrevSelectedCountryCode] =
    useState<SupportedCountryCode | null>(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState(AVAILABLE_COUNTRIES[0].code);

  const memoizedAddresses = useRef(initialAddresses ?? []);
  memoizedAddresses.current = initialAddresses ?? [];
  const formFieldsAndRules = useRef(initialFormFieldsAndRules);
  formFieldsAndRules.current = initialFormFieldsAndRules;

  const {
    control,
    formState: { errors },
    handleSubmit,
    trigger,
    setValue,
    unregister,
    setError,
    reset,
    updateSchema,
  } = useCustomForm();

  useEffect(() => {
    if (
      // We don't consider functions when comparing equalities
      !isEqual(
        JSON.stringify(prevFormFieldsAndRules || {}),
        JSON.stringify(initialFormFieldsAndRules || {}),
      ) ||
      !isEqual(prevAddresses, initialAddresses) ||
      selectedCountryCode !== prevSelectedCountryCode
    ) {
      setPrevFormFieldsAndRules(initialFormFieldsAndRules);
      setPrevAddresses(initialAddresses);
      setPrevSelectedCountryCode(selectedCountryCode);

      const addressesSchema = initialAddresses.reduce((tally, address) => {
        return {
          ...tally,
          ...getFieldsRules(
            COUNTRY_ADDRESS_FIELDS_AND_RULES[selectedCountryCode][address.fieldsAndRulesName],
          ),
        };
      }, {});

      updateSchema(
        { ...getFieldsRules_New(initialFormFieldsAndRules), ...addressesSchema },
        { resetSchema: true },
      );
    }
  }, [
    initialFormFieldsAndRules,
    prevFormFieldsAndRules,
    updateSchema,
    initialAddresses,
    prevAddresses,
    selectedCountryCode,
    prevSelectedCountryCode,
    unregister,
    setValue,
  ]);

  const renderFormRef = useRef({ errors });
  renderFormRef.current.errors = errors;

  const defaultAddressFieldsValues = useMemo(() => {
    return memoizedAddresses.current.reduce((tallyAddresses, address) => {
      const addressFieldName = address.fieldsPrefix.split(
        ADDRESS_PREFIX_SEPARATOR,
      )[0] as keyof typeof defaultCustomer;
      const addressData = defaultCustomer?.[addressFieldName] as TAddress;
      if (addressData) {
        if (addressData.countryCode === selectedCountryCode) {
          const defaultValues = Object.entries(defaultCustomer[addressFieldName] || {}).reduce(
            (tally, [k, v]) => {
              return {
                ...tally,
                [stringUtils.generateCamelCaseString(address.fieldsPrefix, k)]: v,
              };
            },
            {},
          );

          tallyAddresses = { ...tallyAddresses, ...defaultValues };
        }
      }
      return tallyAddresses;
    }, {});
  }, [defaultCustomer, selectedCountryCode]);

  const retrievingRef = useRef(false);
  useEffect(() => {
    if (retrievingRef.current || !retrieveCustomerApiRequest || !customerId) {
      return;
    }

    setIsRetriving(true);
    retrievingRef.current = true;
    retrieveCustomerApiRequest(customerId)
      .then((customer: TObject) => {
        // We currently only support one country in a form,
        // even there are multiple addresses in the form (i.e. Registered address & Principal place of business).
        // That means multiple addresses are all from the same country,
        // thus we only grab the country code of the first address as the country code of form.
        const addressFieldName = memoizedAddresses.current[0]?.fieldsPrefix?.replace(
          ADDRESS_PREFIX_SEPARATOR,
          '',
        );

        // It may be `undefined` due to `Public company` does not have address for now
        if (addressFieldName) {
          setSelectedCountryCode(customer[addressFieldName].countryCode);
        }
        let toBeReset = {};
        const formFieldsNames = getFieldsNamesFromFieldsAndRules_New(formFieldsAndRules.current);

        formFieldsNames.forEach((fieldName) => {
          const name = fieldName
            .replace(basicFieldsPrefix, '')
            .replace(/^./, (c) => c.toLowerCase());

          // Hard code for now
          // We may no need this parts once our API supports no need to specify
          // the countryCode but reading countryCode from the address fields of form
          if (name === 'countryCode') {
            toBeReset = { ...toBeReset, [fieldName]: customer[addressFieldName]?.countryCode };
          } else {
            toBeReset = { ...toBeReset, [fieldName]: customer[name] };
          }
        });

        // Fill form fields except addresses by values
        reset(toBeReset);
        setDefaultCustomer(customer);
        onRetrieveComplete?.(customer);
      })
      .catch((err: any) => {
        const { code, message } = err || { code: 'default', message: SERVER_ERRORS.default };
        setNonFieldErrors([{ code, message, scope: ErrorScope.Form }]);
        onRetrieveComplete?.(null, err);
      })
      .finally(() => {
        setIsRetriving(false);
      });
  }, [customerId, onRetrieveComplete, reset, retrieveCustomerApiRequest]);

  const onDetailsSubmit: SubmitHandler<any> = async (verificationInfo) => {
    if (typeof fieldsNotChangedCallback === 'function') {
      // No any change, directly go next step
      if (!isFieldsChanged) {
        fieldsNotChangedCallback();
        return;
      }
    }

    try {
      setIsSubmitting(true);

      const result = await submitApiRequest(verificationInfo, defaultCustomer);

      const { customer, validationResults } =
        result || ({ customer: {}, validationResults: [] } as TCustomerResponse);

      if (validationResults?.length > 0) {
        showFieldsErrors(validationResults, setError, { basicFieldsPrefix, isAddPrefix: true });
        const nonFieldErrors = generateNonFieldErrors(validationResults);
        setNonFieldErrors(nonFieldErrors);
        return;
      }

      setDefaultCustomer(null);
      onSubmitComplete?.(customer, null, verificationInfo);

      // Reset form fields when submit successfully. This ensures the form is clean for next use.
      reset();
    } catch (err: any) {
      const { code, message } = err || { code: 'default', message: SERVER_ERRORS.default };
      setNonFieldErrors([{ code, message, scope: ErrorScope.Form }]);
      onSubmitComplete?.(null, err, verificationInfo);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fieldsOperations = useMemo(() => {
    const fieldsOperations: Record<string, any> = {};
    for (const [_, fieldsInfo] of Object.entries(formFieldsAndRules.current || {})) {
      for (const fieldInfo of fieldsInfo) {
        switch (fieldInfo.renderType) {
          case 'Text':
            fieldsOperations[fieldInfo.fieldName] = {
              onTextChange: () => setIsFieldsChanged(true),
            };
            break;
          case 'File':
            fieldsOperations[fieldInfo.fieldName] = {
              onFileChange: () => setIsFieldsChanged(true),
            };
            break;
          case 'Checkbox':
            fieldsOperations[fieldInfo.fieldName] = {
              onCheckBoxChange: () => setIsFieldsChanged(true),
            };
            break;
          case 'Date':
            fieldsOperations[fieldInfo.fieldName] = {
              onDateChange: () => setIsFieldsChanged(true),
            };
            break;
          case 'DateRange':
            fieldsOperations[fieldInfo.fieldName] = {
              onDateRangeChange: () => setIsFieldsChanged(true),
            };
            break;
          case 'Select':
            fieldsOperations[fieldInfo.fieldName] = {
              onOptionChange: () => setIsFieldsChanged(true),
              initValue: fieldInfo.items?.[0]?.code,
            };
            break;
          default:
            break;
        }
      }
    }
    return fieldsOperations;
  }, []);

  // It can be seen clearly that this one and above one are quite similar,
  // we should combine them, but due to GenerateFromFields and GenerateFromFields_New
  // are used togeter, thus it comes to we combine two generate form fields first.
  // TODO: Combine this and above one after combining two generate form fields
  const addressFieldsOperations = useMemo(
    () => (addressFieldsAndRules: TFormFieldsAndRules) => {
      const fieldsOperations: Record<string, any> = {};
      for (const [fieldName, fieldInfo] of Object.entries(addressFieldsAndRules || {})) {
        switch (fieldInfo.renderType) {
          case 'Text':
            fieldsOperations[fieldName] = {
              onTextChange: () => setIsFieldsChanged(true),
            };
            break;
          case 'Select':
            fieldsOperations[fieldName] = {
              onOptionChange: () => setIsFieldsChanged(true),
              initValue: fieldInfo.items?.[0]?.code,
            };
            break;
          default:
            break;
        }
      }
      return fieldsOperations;
    },
    [],
  );

  const submitForm = handleSubmit(onDetailsSubmit);

  const FormRenders = useCallback(() => {
    return (
      <Stack width="100%" direction="column" component="form" autoComplete="off">
        <GenerateFormFields_New
          formFieldsAndRules={formFieldsAndRules.current}
          control={control}
          errors={renderFormRef.current.errors}
          fieldsOperations={fieldsOperations}
        />
        {memoizedAddresses.current.map((address, index) => {
          const selectedCountryAddressFieldsAndRules =
            COUNTRY_ADDRESS_FIELDS_AND_RULES[selectedCountryCode][address.fieldsAndRulesName];
          const sameAsAddressFieldsAndRules =
            COUNTRY_ADDRESS_FIELDS_AND_RULES[selectedCountryCode][
              address.sameAsAddress?.addressFieldsAndRulesName
            ];
          const sameAsAddress = {
            ...address.sameAsAddress,
            fieldsAndRules: sameAsAddressFieldsAndRules,
          };
          return (
            <Fragment key={index}>
              {address.renderType === 'AddressOnly' && (
                <AddressComponent
                  label={address.label || address.inputLabel}
                  countryCode={selectedCountryCode}
                  addressFieldsAndRules={selectedCountryAddressFieldsAndRules}
                  control={control}
                  errors={renderFormRef.current.errors}
                  trigger={trigger}
                  setValue={setValue}
                  defaultValues={defaultAddressFieldsValues}
                  onPlaceSelected={() => setIsFieldsChanged(true)}
                  dataCy="text-address-auto-complete"
                  placeholder={address.inputPlaceholder}
                  inputsFieldsOperations={addressFieldsOperations(
                    selectedCountryAddressFieldsAndRules,
                  )}
                  sameAsAddress={sameAsAddress}
                />
              )}
              {address.renderType === 'AddressWithCountrySelect' && (
                <AddressWithCountrySelectComponent
                  formValidations={selectedCountryAddressFieldsAndRules}
                  control={control}
                  errors={renderFormRef.current.errors}
                  trigger={trigger}
                  setValue={setValue}
                  countryCode={selectedCountryCode}
                  defaultValues={defaultAddressFieldsValues}
                  label={address.label}
                  addressInputLabel={address.inputLabel}
                  addressInputPlaceholder={address.inputPlaceholder}
                  onPlaceSelected={() => setIsFieldsChanged(true)}
                  inputsFieldsOperations={addressFieldsOperations(
                    selectedCountryAddressFieldsAndRules,
                  )}
                  onCountryChange={(newCountryCode) => {
                    const prevAddressFieldsAndRules =
                      COUNTRY_ADDRESS_FIELDS_AND_RULES[prevSelectedCountryCode][
                        address.fieldsAndRulesName
                      ];
                    const prevFieldsNames =
                      getFieldsNamesFromFieldsAndRules(prevAddressFieldsAndRules);
                    unregister(prevFieldsNames);
                    setSelectedCountryCode(newCountryCode);
                  }}
                />
              )}
            </Fragment>
          );
        })}
      </Stack>
    );
  }, [
    control,
    fieldsOperations,
    selectedCountryCode,
    trigger,
    setValue,
    defaultAddressFieldsValues,
    addressFieldsOperations,
    prevSelectedCountryCode,
    unregister,
  ]);

  return {
    FormRenders,
    nonFieldErrors,
    isSubmitting,
    isRetriving,
    submitForm,
    errors: renderFormRef.current.errors,
    reset,
    setDefaultCustomer,
  };
}
