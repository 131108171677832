export type TNavItem = {
  path?: string;
  label: string;
  icon?: any;
  role?: Role;
  isDivider?: boolean;
};

export enum Role {
  Customer = 'customer',
  Admin = 'admin',
  Client = 'client',
}

export type TDataTableCell = {
  dataCy?: string;
  value: any;
};

export type TDataTableRow = {
  dataCy?: string;
  cells: TDataTableCell[];
};

export type TOrganisation = {
  id: string;
  name: string;
  children: TOrganisation[];
};

// TODO: Delete this
// check https://greengatefintech.atlassian.net/browse/VME-1694 for details.
export enum IdentityType {
  INDIVIDUAL = 'Individual',
  SMSF = 'Self Managed Super Fund',
  PUBLIC_COMPANY = 'Public Company',
  PRIVATE_COMPANY = 'Private Company',
  UNREGULATED_TRUST = 'Unregulated Trust',
  REGULATED_TRUST = 'Regulated Trust',
  PARTNERSHIP = 'Partnership',
  SOLE_TRADER = 'Sole Trader',
  UNKNOWN = 'Unknwon',
}

// TODO: Delete this
// check https://greengatefintech.atlassian.net/browse/VME-1694 for details.
export enum IdentityStatus {
  DRAFT = 'Draft',
  IN_PROGRESS = 'In Progress',
  REJECTED = 'Action required',
  VERIFIED = 'Verified',
}

export type TPermissionOrganisation = {
  reportingEntityName: string;
  subEntityName: string;
  orgId: string;
};

// TODO: Delete this
// check https://greengatefintech.atlassian.net/browse/VME-1694 for details.
export type TIdentity = {
  name: string;
  id: string;
  type: IdentityType;
  status: IdentityStatus;
};

export enum PermissionStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Rejected = 'Rejected',
  Pending = 'Pending',
}

export type TPermission = {
  id: string;
  permissionStatus: PermissionStatus;
  addedDate: string;
  removedDate?: string;
} & TPermissionOrganisation;

export enum SetupState {
  // Set up a passkey step1
  FIDO_STEP1,
  // Set up a passkey step2
  FIDO_STEP2,
  // Set up an authenticator
  TOTP,
  LOADING,
  NO_CREDENTIAL,
  ENTER_CODE,
}
