import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AutoAwesomeRounded from '@mui/icons-material/AutoAwesomeRounded';
import BlockIcon from '@mui/icons-material/Block';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseFullscreenRounded from '@mui/icons-material/CloseFullscreenRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import ImageNotSupportedRoundedIcon from '@mui/icons-material/ImageNotSupportedRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded';
import PendingIcon from '@mui/icons-material/Pending';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import RemoveIcon from '@mui/icons-material/Remove';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import ReviewsRoundedIcon from '@mui/icons-material/ReviewsRounded';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';
import { COLOR_MAPPING, TColorName, TIconName } from './constants';

const ICON_MAPPING: { [iconName in TIconName]: typeof SvgIcon } = {
  checkCircle: CheckCircleIcon,
  block: BlockIcon,
  remove: RemoveIcon,
  pending: PendingIcon,
  checkCircleRounded: CheckCircleRoundedIcon,
  warningRounded: WarningRoundedIcon,
  cancelRounded: CancelRoundedIcon,
  blockRounded: BlockRoundedIcon,
  radioButtonUncheckedRounded: RadioButtonUncheckedRoundedIcon,
  removeCircleRounded: RemoveCircleRoundedIcon,
  errorRounded: ErrorRoundedIcon,
  keyboardArrowDownRounded: KeyboardArrowDownRoundedIcon,
  deleteRounded: DeleteRoundedIcon,
  warningAmberRounded: WarningAmberRoundedIcon,
  addRounded: AddRoundedIcon,
  closeRounded: CloseRoundedIcon,
  editRounded: EditRoundedIcon,
  verifiedRounded: VerifiedRoundedIcon,
  arrowRightRounded: KeyboardArrowRightRoundedIcon,
  arrowLeftRounded: KeyboardArrowLeftRoundedIcon,
  LockRounded: LockRoundedIcon,
  emailRounded: EmailRoundedIcon,
  moreVertRounded: MoreVertRoundedIcon,
  closeFullscreenRounded: CloseFullscreenRounded,
  autoAwesomeRounded: AutoAwesomeRounded,
  reviewsRounded: ReviewsRoundedIcon,
  fileCopyRounded: FileCopyRoundedIcon,
  uploadFileIcon: UploadFileIcon,
  fileDownloadRounded: FileDownloadRoundedIcon,
  imageNotSupportedRounded: ImageNotSupportedRoundedIcon,
  openInNewRounded: OpenInNewRoundedIcon,
  pdfRounded: PictureAsPdfRoundedIcon,
};

export default function ColoredIcon({
  iconName,
  colorName,
  iconSize = 'small',
  sx,
  isCompact,
  ...props
}: {
  iconName: TIconName;
  colorName?: TColorName;
  iconSize?: 'small' | 'inherit' | 'large' | 'medium';
  isCompact?: boolean;
} & SvgIconProps) {
  const theme = useTheme();
  const color = COLOR_MAPPING[colorName]?.(theme);
  const Icon = ICON_MAPPING[iconName];
  return (
    <Icon
      sx={{
        alignSelf: 'center',
        flex: 'auto',
        fill: isCompact
          ? color?.fillColor || color?.borderColor || color?.iconColor
          : color?.iconColor || color?.borderColor || color?.fillColor,
        ...sx,
      }}
      fontSize={iconSize}
      {...props}
    />
  );
}
