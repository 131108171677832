import { useState } from 'react';
import dynamic from 'next/dynamic';
import { ReactQuillProps } from 'react-quill';
import { Box, useTheme } from '@mui/material';
import 'react-quill/dist/quill.snow.css';
import './style.css';
import { DisplayErrors } from '../Form';
import tokens from '@verifime/design-tokens';

const ReactQuill = dynamic(import('react-quill'), { ssr: false });

/**
 * We treat those situations as invalid value
 *
 * 1.<p><br></p> which happens when deleting all of previous content or typing enter keys
 *
 * 2.<p>\s+</p> just typing spaces
 *
 */
const invalidValueRegex = /^[(<p><br><\/p>)(<p>\s+<\/p>)]+$/;

export default function TextEditor({
  initialValue,
  onTextChange,
  errorMessage,
  ...props
}: {
  initialValue?: string;
  onTextChange?: (value: string) => void;
  errorMessage?: string;
} & Omit<ReactQuillProps, 'onChange'>) {
  const [value, setValue] = useState(initialValue || '');
  const theme = useTheme();

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['blockquote', 'link'],
      ['clean'],
    ],
  };

  const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'script',
    'header',
    'blockquote',
    'code-block',
    'indent',
    'list',
    'direction',
    'align',
    'link',
    'image',
    'video',
    'formula',
  ];

  const editorOverrideCssProperties = {
    ['--placeholder-color']: theme.palette.text.disabled,
    ['--header-icon-color']:
      theme.palette.mode === 'dark' ? tokens.colorGrey300 : tokens.colorGrey600,
  };

  return (
    <Box>
      <ReactQuill
        {...props}
        theme="snow"
        modules={modules}
        formats={formats}
        value={value}
        onChange={(value) => {
          const validValue = value.replace(invalidValueRegex, '');
          setValue(validValue);
          if (typeof onTextChange === 'function') {
            onTextChange(validValue);
          }
        }}
        style={{
          // TODO: change it once border tokens available
          border: errorMessage && `1px solid ${theme.palette.error.main}`,
          borderRadius: tokens.spacing2xs,
          backgroundColor: theme.palette.action.hover,
          color: theme.palette.text.primary,
          ...editorOverrideCssProperties,
        }}
      />
      {errorMessage && <DisplayErrors errors={[{ message: errorMessage }]} />}
    </Box>
  );
}
