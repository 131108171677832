import React, { useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import * as otpauth from 'otpauth';
import tokens from '@verifime/design-tokens';
import { SetupState } from '../../types';
import {
  AuthenticatorSetupHeader,
  AuthenticatorInfo,
  AuthenticatorCode,
} from '../Authenticator';
import { LoginMethod } from '@verifime/utils';

export type TSetupAuthenticatorAppProps = {
  otpIssuer?: string;
  otpUserDisplayName?: string;
  otpDigits?: number;
  setupComplete?: (loginMethod: LoginMethod) => void;
  registerOTP: (secret: string | (() => string | Promise<string>)) => Promise<string>;
  setSetupState?: (setupState: SetupState) => void;
  displayBackOnEntry?: boolean;
  displayAddPasskey?: boolean;
};

export default function SetupAuthenticatorApp({
  setSetupState,
  otpIssuer,
  otpUserDisplayName,
  registerOTP,
  setupComplete,
  displayBackOnEntry = true,
  otpDigits = 6,
  displayAddPasskey = true,
}: TSetupAuthenticatorAppProps) {
  const [currentStep, setCurrentStep] = useState(SetupState.TOTP);
  const [totp, setTotp] = useState<otpauth.TOTP>();

  // This useEfect is required so that the TOTP instance doesn't change
  // across re-renders. It is important to share the same TOTP instance
  // in the AuthenticatorCode component as well, so that the code entered
  // is validated against the correct TOTP instance.
  // This TOTP setup (useEffect) runs only once at component mount.
  useEffect(() => {
    const totp: otpauth.TOTP = new otpauth.TOTP({
      issuer: otpIssuer,
      label: otpUserDisplayName,
      algorithm: 'SHA1',
      digits: otpDigits,
      secret: new otpauth.Secret(),
    });
    setTotp(totp);
  }, []);

  return (
    <>
      {currentStep === SetupState.TOTP && (
        <>
          <AuthenticatorSetupHeader
            displayBack={displayBackOnEntry}
            onBackClick={() => setSetupState(SetupState.FIDO_STEP1)}
            title="Setup Authenticator App"
            subtitle="You will use this to log in to VerifiMe safely next time"
          />
          <Stack gap={tokens.spacingLg}>
            <AuthenticatorInfo
              displayAddPasskey={displayAddPasskey}
              totp={totp}
              onContinue={() => setCurrentStep(SetupState.ENTER_CODE)}
              addPasskey={() => setSetupState(SetupState.FIDO_STEP2)}
            />
          </Stack>
        </>
      )}

      {currentStep === SetupState.ENTER_CODE && (
        <>
          <AuthenticatorSetupHeader
            displayBack={true}
            onBackClick={() => setCurrentStep(SetupState.TOTP)}
            title="Confirm Authenticator App Setup"
            subtitle="If you missed setting it up in the last step, go back and do it now"
          />
          <Stack gap={tokens.spacingLg}>
            <AuthenticatorCode totp={totp} registerOTP={registerOTP} onDone={setupComplete} />
          </Stack>
        </>
      )}
    </>
  );
}
