import { useState } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper, useTheme } from '@mui/material';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import NavIcon from './NavIcon';
import Link from 'next/link';
import { TNavItem } from '../types';

const StyledNavIcon = styled.svg`
  ${NavIcon}
  transform: scale(0.7);
`;

const LinkAction = (props: any) => {
  return <BottomNavigationAction component={Link} {...props} />;
};

const BottomNav = ({ navItems }: { navItems: TNavItem[] }) => {
  const [value, setValue] = useState(0);
  const router = useRouter();
  const theme = useTheme();

  const fillColor = (path: string) => {
    return router.pathname === path ? theme.palette.primary.light : theme.palette.common.white;
  };

  return (
    <Paper
      sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: theme.zIndex.appBar }}
      elevation={5}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
        }}
      >
        {navItems.map((navItem, index) => {
          return (
            <LinkAction
              key={navItem.label}
              value={index}
              icon={
                <StyledNavIcon
                  as={navItem.icon}
                  fillcolor={fillColor(navItem.path)}
                ></StyledNavIcon>
              }
              label={navItem.label}
              href={navItem.path}
            ></LinkAction>
          );
        })}
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNav;
