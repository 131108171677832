import { Box, Link, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { stringUtils } from '@verifime/utils';
import { ColoredIcon } from '../ColoredIconAndChip';
import { TDocumentUrl } from './types';

/**
 * We don't implement PDF thumbnail for now.
 */
export default function PdfThumbnail({ url, filename }: Readonly<TDocumentUrl>) {
  return (
    <Stack gap={tokens.spacingBase}>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: (theme) => theme.palette.divider,
          borderRadius: tokens.borderRadiusSm,
          aspectRatio: '25/16',
        }}
      >
        <ColoredIcon iconName="pdfRounded" colorName="textDisabled" />
      </Stack>
      <Link href={url} target="_blank" sx={{ display: 'flex', gap: tokens.spacing2xs }}>
        <Typography>{stringUtils.ellipsis(filename || url, 'middle')}</Typography>
        <Box>
          <ColoredIcon iconName="openInNewRounded" />
        </Box>
      </Link>
    </Stack>
  );
}
