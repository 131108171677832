import {
  TextField,
  Typography,
  Button,
  Chip,
  Box,
  Paper,
  useTheme,
  InputAdornment,
} from '@mui/material';
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import debounce from 'lodash/debounce';
import { SubmitHandler } from 'react-hook-form';
import FilterListIcon from '@mui/icons-material/FilterList';
import ResponsiveBox from './ResponsiveBox';
import tokens from '@verifime/design-tokens';
import SearchIcon from '@mui/icons-material/Search';
import {
  GenerateFormFields_New,
  TFormDateRangeLabels,
  TFormFieldsAndRules_New,
  TFromFieldInfo_New,
  getFieldInfoByFieldName,
  getFieldsNamesFromFieldsAndRules_New,
  getFieldsRules_New,
  useCustomForm,
} from './Form';
import { formatToDDMMYYYY, formatToYYYYMMDD, stringUtils } from '@verifime/utils';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { ColoredChip } from './ColoredIconAndChip';

export type TFilterRules = {
  title: string;
  rules: TFormFieldsAndRules_New;
};

export type TChipValueType = 'Date' | 'DateRange' | 'String' | 'Boolean';
export type TRuleChipFieldsValue = {
  [key in keyof TFormDateRangeLabels]: { originalName: string; value: string };
};
export type TRuleChip = {
  fieldName: string;
  fieldLabel: string;
  fieldValue: string | TRuleChipFieldsValue;
  fieldValueType: TChipValueType;
  displayValue: string; // Used to display in Chip
};

type TUpdateUrlActions = 'updateSearchText' | 'updateFilters';

const DATE_RANGE_FIELDS_SEPARATOR = '--';
const DATE_RANGE_FIELD_VALUE_SEPARATOR = ':';

export type TSearch = {
  onSearchTextChange: (newFilterText: string) => void;
  onFilterRulesChange?: (rules: TRuleChip[]) => void;
  label?: ReactNode;
  dataCy?: string;
  // To delay fire search text change event for better performance, default is 300ms.
  // If you'd like to fire search text event immediately just set it to 0
  delayFireSearchText?: number;
  filterRules?: TFilterRules;
  // If default validRules check does not meet requirements,
  // use this to customise check validation rules
  customiseValidRules?: (verificationInfo: Record<string, any>) => TRuleChip[];
  initialSearchText?: string;
  initialFilterRules?: TRuleChip[];
  customiseUpdateUrl?: (routeQuery: ParsedUrlQuery) => void;
};

/**
 * This Search component do three things:
 * 
 * 1. To allow search by text
 * 2. To allow search by filters
 * 3. Add/update serch text and filters to url params
    
    such as:
    search text is `Hello world`
    the filter is a date range `dateCreated` selected both from and to fields

    then the generated url params will be:
    
    searchText=Hello world&dateCreated=From:2023-07-21--To:2023-08-25
    
    To make dateRange works passing as the param of the url,
    please given in this format:

    fieldName=startFieldLable:fromValue--endFieldLabel:endValue
    
    such as:
    
    1. dateCreated=From:2023-07-21--To:2023-08-25
    2. dateCreated=From:2023-07-21
    3. dateCreated=To:2023-08-25
 */
export default function Search({
  label,
  onSearchTextChange,
  onFilterRulesChange,
  dataCy,
  delayFireSearchText = 300,
  filterRules,
  customiseValidRules,
  customiseUpdateUrl,
  initialSearchText,
  initialFilterRules,
}: TSearch) {
  const [searchText, setSearchText] = useState('');
  const [appliedRules, setAppliedRules] = useState<TRuleChip[]>([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isInitialedSearchText, setIsInitisaledSearchText] = useState(false);
  const [isInitialedFilterRules, setIsInitialedFilterRules] = useState(false);
  const [defaultValues, setDefaultValues] = useState<Record<string, any> | null>({});
  const [updateUrlActions, setUpdateUrlActions] = useState<
    { [key in TUpdateUrlActions]?: boolean } | null
  >(null);
  const [isInitialedSearchByUrl, setIsInitialedSearchByUrl] = useState(false);
  const [isSearchFocused, setSearchFocused] = useState(false);

  const filterRulesSchema = useMemo(
    () => getFieldsRules_New(filterRules?.rules),
    [filterRules?.rules],
  );

  const router = useRouter();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    resetField,
  } = useCustomForm({ schema: filterRulesSchema });

  useEffect(() => {
    if (typeof onFilterRulesChange === 'function') {
      onFilterRulesChange(appliedRules);
    }
  }, [appliedRules]);

  useEffect(() => {
    if (initialSearchText && !isInitialedSearchText) {
      setSearchText(initialSearchText);
      setIsInitisaledSearchText(true);
    }
  }, [initialSearchText, isInitialedSearchText]);

  useEffect(() => {
    if (initialFilterRules?.length > 0 && !isInitialedFilterRules) {
      const defaultValues = initialFilterRules.reduce((tally, rule) => {
        let fieldDefaultValue: Record<string, any> = {
          [rule.fieldName]: rule.fieldValue,
        };
        if (rule.fieldValueType === 'DateRange') {
          fieldDefaultValue = {};
          Object.entries(rule.fieldValue as TRuleChipFieldsValue).forEach(([_, item]) => {
            fieldDefaultValue[
              stringUtils.generateCamelCaseString(rule.fieldName, item.originalName)
            ] = formatToYYYYMMDD(item.value);
          });
        }

        return {
          ...tally,
          ...fieldDefaultValue,
        };
      }, {});
      setDefaultValues(defaultValues);
      setIsInitialedFilterRules(true);
    }
  }, [initialFilterRules, isInitialedFilterRules]);

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((newFilterText: string) => {
        if (typeof onSearchTextChange === 'function') {
          onSearchTextChange(newFilterText);
          setUpdateUrlActions({
            updateSearchText: true,
          });
        }
      }, delayFireSearchText),
    [delayFireSearchText],
  );

  useEffect(() => {
    if (isInitialedSearchByUrl) {
      return;
    }

    if (Object.keys(router.query).length < 1) {
      return;
    }

    setIsInitialedSearchByUrl(true);

    const { searchText, ...filters } = router.query;

    if (searchText) {
      setSearchText(searchText as string);
      if (typeof onSearchTextChange === 'function') {
        onSearchTextChange(searchText as string);
      }
    }

    if (filters) {
      if (!filterRules?.rules) {
        return;
      }
      const initialFilterRules: TRuleChip[] = generateFiltersByUrl(filters, filterRules);
      setAppliedRules(initialFilterRules);
    }
  }, [router.query, isInitialedSearchByUrl, filterRules?.rules]);

  useEffect(() => {
    if (!updateUrlActions) {
      return;
    }

    const { updateSearchText, updateFilters } = updateUrlActions;
    let routeQuery = router.query;

    if (typeof customiseUpdateUrl === 'function') {
      customiseUpdateUrl(routeQuery);
    }

    if (updateSearchText) {
      if (searchText) {
        routeQuery = { ...routeQuery, searchText };
      } else {
        delete routeQuery.searchText;
      }
    }

    if (updateFilters) {
      const { searchText, ...filters } = routeQuery;
      const filterRulesFieldsName = getFieldsNamesFromFieldsAndRules_New(filterRules.rules);
      if (appliedRules.length > 0) {
        // Delete all removed filters from params
        Object.keys(filters).forEach((filterKey) => {
          const isDeletedRule =
            appliedRules.find((appliedRule) => appliedRule.fieldName === filterKey) == null;
          if (filterRulesFieldsName.includes(filterKey)) {
            if (isDeletedRule) {
              delete routeQuery[filterKey];
            }
          }
        });

        addAllFilersIntoURL(appliedRules, routeQuery);
      } else {
        // Delete all filters from url params
        Object.keys(filters).forEach((filterKey) => {
          if (filterRulesFieldsName.includes(filterKey)) {
            delete routeQuery[filterKey];
          }
        });
      }
    }
    router.push({ href: '/', query: routeQuery });
    setUpdateUrlActions(null);
  }, [searchText, appliedRules, router, updateUrlActions, filterRules?.rules]);

  const handleSearchTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newFilterText = event.target.value;
    setSearchText(newFilterText);
    debouncedSearchHandler(newFilterText);
  };

  const handleDeleteRuleChip = (ruleChipToDelete: TRuleChip) => {
    setDefaultValues((defaultValues) => {
      const updatedDefaultValues = { ...defaultValues };
      delete updatedDefaultValues[ruleChipToDelete.fieldName];
      if (ruleChipToDelete.fieldValueType === 'DateRange') {
        const dateRangeFields = getDateRangeFields(ruleChipToDelete);
        dateRangeFields.forEach((field) => delete updatedDefaultValues[field]);
      }
      return updatedDefaultValues;
    });

    setAppliedRules((rules) =>
      rules.filter((rule) => rule.fieldName !== ruleChipToDelete.fieldName),
    );

    setUpdateUrlActions({
      updateFilters: true,
    });

    if (ruleChipToDelete.fieldValueType === 'DateRange') {
      const dateRangeFields = getDateRangeFields(ruleChipToDelete);
      dateRangeFields.forEach((field) => resetField(field));
    } else {
      resetField(ruleChipToDelete.fieldName);
    }
  };

  const handleClearAllFilteres = () => {
    setAppliedRules([]);
    setUpdateUrlActions({
      updateFilters: true,
    });
    setDefaultValues({});
    reset();
  };

  const onApplyRules: SubmitHandler<Record<string, any>> = async (verificationInfo) => {
    const appliedDateRangeRules: Map<string, TRuleChip> = new Map();
    const dateRangeFields = [];

    for (const [fieldName, fieldValue] of Object.entries(verificationInfo)) {
      if (fieldValue) {
        const fieldInfo = getFieldInfoByFieldName(filterRules?.rules, fieldName);
        if (fieldInfo.renderType === 'DateRange') {
          dateRangeFields.push(fieldName);
          appendAppliedDateRangeRule(appliedDateRangeRules, fieldInfo, fieldName, fieldValue);
        }
      }
    }

    const noDateRangesVerificationInfo = Object.assign({}, verificationInfo);
    dateRangeFields.forEach((field) => delete noDateRangesVerificationInfo[field]);

    const appliedOtherRules = Object.entries(noDateRangesVerificationInfo).reduce(
      (tally: TRuleChip[], [fieldName, originalFieldValue]) => {
        if (originalFieldValue) {
          const fieldInfo = getFieldInfoByFieldName(filterRules?.rules, fieldName);
          if (fieldInfo) {
            const { displayValue, fieldValueType, fieldValue } = generateFieldsForRuleChip(
              fieldInfo,
              originalFieldValue,
            );

            tally.push({
              fieldName,
              displayValue,
              fieldValueType,
              fieldValue,
              fieldLabel: fieldInfo.label,
            });
          }
        }
        return tally;
      },
      [],
    );

    let validRules = [...appliedDateRangeRules.values(), ...appliedOtherRules];

    if (typeof customiseValidRules === 'function') {
      validRules = customiseValidRules(verificationInfo);
    }

    if (Object.keys(validRules).length < 1) {
      // Reset all fields if there are no any valid rules
      handleClearAllFilteres();
    } else {
      setAppliedRules(validRules);
      setUpdateUrlActions({
        updateFilters: true,
      });
    }

    setIsOpenFilter(false);
  };

  const rules = Object.values(filterRules?.rules || {}).filter((rule) => rule.length > 0);
  const isShowFilteres = Object.keys(filterRules?.rules || {}).length > 0 && rules.length > 0;

  const theme = useTheme();

  return (
    <ResponsiveBox responsiveOnWidth="xl" data-cy={dataCy}>
      <ResponsiveBox responsiveOnWidth="none" verticalAlign="middle">
        <TextField
          sx={{ minWidth: [200, 300] }} // TODO: change it when tokens available
          type="search"
          label={label}
          value={searchText}
          onChange={handleSearchTextChange}
          data-cy={dataCy}
          InputProps={{
            startAdornment: isSearchFocused && (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
        />
        {isShowFilteres && (
          <>
            <Button onClick={() => setIsOpenFilter(!isOpenFilter)}>
              <FilterListIcon /> Filters
            </Button>

            {isOpenFilter && (
              <Paper
                sx={{
                  position: 'absolute',
                  top: '100%', // At the bottom of it's parent
                  maxWidth: tokens.sizeSm,
                  zIndex: theme.zIndex.appBar,
                }}
              >
                <ResponsiveBox responsiveOnWidth="xl" sx={{ padding: tokens.spacingBase }}>
                  {filterRules.title && (
                    <Typography variant="h5" color="textPrimary">
                      {filterRules.title}
                    </Typography>
                  )}
                  <GenerateFormFields_New
                    formFieldsAndRules={filterRules.rules}
                    formFieldsWithDefaultValues={defaultValues}
                    control={control}
                    errors={errors}
                  />
                  <ResponsiveBox responsiveOnWidth="xs" align="right" responsiveAlign="right">
                    <Button variant="outlined" onClick={() => setIsOpenFilter(false)}>
                      Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSubmit(onApplyRules)}>
                      Apply
                    </Button>
                  </ResponsiveBox>
                </ResponsiveBox>
              </Paper>
            )}
          </>
        )}
      </ResponsiveBox>
      {appliedRules.length > 0 && (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: tokens.spacingBase }}>
          {appliedRules.map((rule) => (
            <ColoredChip
              key={rule.fieldName}
              label={`${rule.fieldLabel}: ${rule.displayValue}`}
              onDelete={() => handleDeleteRuleChip(rule)}
              variant="outlined"
              colorName="infoDark"
            />
          ))}
          <Button color="error" onClick={handleClearAllFilteres}>
            Clear all
          </Button>
        </Box>
      )}
    </ResponsiveBox>
  );
}

function generateFiltersByUrl(
  filters: { [key: string]: string | string[] },
  filterRules: TFilterRules,
) {
  const initialFilterRules: TRuleChip[] = [];
  for (const [filterName, filterValue] of Object.entries(filters)) {
    let ruleDefinition: TFromFieldInfo_New = null;
    for (const [_, items] of Object.entries(filterRules.rules)) {
      ruleDefinition = items.find((item) => item.fieldName === filterName);
      if (ruleDefinition) {
        break;
      }
    }

    if (ruleDefinition) {
      let { displayValue, fieldValueType, fieldValue } = generateFieldsForRuleChip(
        ruleDefinition,
        filterValue as string,
      );

      if (ruleDefinition.renderType === 'DateRange') {
        const dateRangeFieldsValues = (filterValue as string)
          .split(DATE_RANGE_FIELDS_SEPARATOR)
          .filter(Boolean);

        if (dateRangeFieldsValues.length < 1) {
          break;
        }

        fieldValueType = 'DateRange';

        fieldValue = {} as TRuleChipFieldsValue;

        dateRangeFieldsValues.forEach((field) => {
          const [name, value] = field.split(DATE_RANGE_FIELD_VALUE_SEPARATOR);
          const [matchedLabelName, matchedLabelValue] = Object.entries(
            ruleDefinition.dateRangeFieldsLabels,
          ).find(([_, labelValue]) => labelValue === name);
          // @ts-ignore
          fieldValue[matchedLabelName] = {
            originalName: matchedLabelValue,
            value: new Date(value),
          };
        });

        if (dateRangeFieldsValues.length === 2) {
          displayValue = `${formatToDDMMYYYY(fieldValue.start.value)} - ${formatToDDMMYYYY(
            fieldValue.end.value,
          )}`;
        } else {
          const [lableName, value] = dateRangeFieldsValues[0].split(
            DATE_RANGE_FIELD_VALUE_SEPARATOR,
          );
          displayValue = lableName + ' ' + formatToDDMMYYYY(value);
        }
      }

      initialFilterRules.push({
        fieldValueType,
        fieldValue,
        displayValue,
        fieldName: ruleDefinition.fieldName,
        fieldLabel: ruleDefinition.label,
      });
    }
  }
  return initialFilterRules;
}

function addAllFilersIntoURL(appliedRules: TRuleChip[], routeQuery: ParsedUrlQuery) {
  appliedRules.forEach((appliedRule) => {
    switch (appliedRule.fieldValueType) {
      case 'DateRange':
        if (Object.keys(appliedRule.fieldValue).length === 2) {
          const { start, end } = appliedRule.fieldValue as TRuleChipFieldsValue;
          const generatedStartParam = `${
            start.originalName
          }${DATE_RANGE_FIELD_VALUE_SEPARATOR}${formatToYYYYMMDD(start.value)}`;

          const generatedEndParam = `${
            end.originalName
          }${DATE_RANGE_FIELD_VALUE_SEPARATOR}${formatToYYYYMMDD(end.value)}`;

          routeQuery[
            appliedRule.fieldName
          ] = `${generatedStartParam}${DATE_RANGE_FIELDS_SEPARATOR}${generatedEndParam}`;
        } else {
          const fieldValues = appliedRule.fieldValue as TRuleChipFieldsValue;
          const validFieldValue = fieldValues?.start || fieldValues?.end;

          routeQuery[appliedRule.fieldName] = `${
            validFieldValue.originalName
          }${DATE_RANGE_FIELD_VALUE_SEPARATOR}${formatToYYYYMMDD(validFieldValue.value)}`;
        }
        break;
      case 'Date':
        routeQuery[appliedRule.fieldName] = formatToYYYYMMDD(appliedRule.fieldValue as string);
        break;
      default:
        routeQuery[appliedRule.fieldName] = appliedRule.fieldValue as string;
        break;
    }
  });
}

function getDateRangeFields(ruleChipToDelete: TRuleChip) {
  const dateRangeFields = [];
  for (const [, subFieldInfo] of Object.entries(ruleChipToDelete.fieldValue)) {
    dateRangeFields.push(
      stringUtils.generateCamelCaseString(ruleChipToDelete.fieldName, subFieldInfo.originalName),
    );
  }
  return dateRangeFields;
}

function appendAppliedDateRangeRule(
  appliedDateRangeRules: Map<string, TRuleChip>,
  fieldInfo: TFromFieldInfo_New,
  fieldName: string,
  fieldValue: any,
) {
  // Both fields of the dateRange have values
  if (appliedDateRangeRules.has(fieldInfo.fieldName)) {
    const appliedRule = appliedDateRangeRules.get(fieldInfo.fieldName);
    const [labelName, labelValue] = getDateRangeFieldLabel(fieldInfo, fieldName);

    const pairFields = {
      ...(appliedRule.fieldValue as TRuleChipFieldsValue),
      [labelName]: {
        originalName: labelValue,
        value: fieldValue,
      },
    };

    appliedRule.fieldValue = pairFields;
    appliedRule.displayValue = `${formatToDDMMYYYY(pairFields.start.value)} - ${formatToDDMMYYYY(
      pairFields.end.value,
    )}`;
  } else {
    const [labelName, labelValue] = getDateRangeFieldLabel(fieldInfo, fieldName);
    appliedDateRangeRules.set(fieldInfo.fieldName, {
      fieldName: fieldInfo.fieldName,
      displayValue: labelValue + ' ' + formatToDDMMYYYY(fieldValue),
      fieldValueType: 'DateRange',
      fieldValue: {
        [labelName]: {
          originalName: labelValue,
          value: fieldValue,
        },
      } as TRuleChipFieldsValue,
      fieldLabel: fieldInfo.label,
    });
  }
}

function getDateRangeFieldLabel(fieldInfo: TFromFieldInfo_New, fieldName: string) {
  let fieldLabel: [labelName: string, labelValue: string] = ['', ''];
  for (const [labelName, labelValue] of Object.entries(fieldInfo.dateRangeFieldsLabels)) {
    if (stringUtils.generateCamelCaseString(fieldInfo.fieldName, labelValue) === fieldName) {
      fieldLabel = [labelName, labelValue];
      break;
    }
  }
  return fieldLabel;
}

function generateFieldsForRuleChip(
  fieldInfo: TFromFieldInfo_New,
  initialFieldValue: string,
): { displayValue: string; fieldValueType: TChipValueType; fieldValue: any } {
  let displayValue = initialFieldValue;
  let fieldValueType: TChipValueType = 'String';
  let fieldValue: any = initialFieldValue;
  switch (fieldInfo.renderType) {
    case 'Date':
      displayValue = formatToDDMMYYYY(initialFieldValue);
      fieldValueType = 'Date';
      break;
    case 'Select':
      const selectedItem = fieldInfo.items?.find((item) => item.code === initialFieldValue);
      displayValue = selectedItem?.label || '';
      break;
    case 'Checkbox':
      displayValue = initialFieldValue + '' === 'true' ? 'Yes' : 'No';
      fieldValueType = 'Boolean';
      fieldValue = initialFieldValue + '' === 'true';
      break;
    default:
      break;
  }
  return { displayValue, fieldValueType, fieldValue };
}
