import { useCallback, useState } from 'react';
import { FieldValues, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

type useCustomFormSchema<TFieldValues> = any;

type UseCustomFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
> = UseFormReturn<TFieldValues, TContext> & {
  updateSchema: (
    newSchema: useCustomFormSchema<TFieldValues>,
    {
      ignoreSchema,
      resetSchema,
    }?: { ignoreSchema?: useCustomFormSchema<TFieldValues>; resetSchema?: boolean },
  ) => void;
};

export const useCustomForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: Omit<UseFormProps<TFieldValues, TContext>, 'resolver'> & {
    schema?: useCustomFormSchema<TFieldValues>;
  },
): UseCustomFormReturn<TFieldValues> => {
  const [schema, setSchema] = useState<useCustomFormSchema<TFieldValues> | null>(
    yup.object().shape(props?.schema) ?? null,
  );

  const updateSchema = useCallback(
    (
      newSchema: useCustomFormSchema<TFieldValues>,
      {
        ignoreSchema = {},
        resetSchema = false,
      }: {
        ignoreSchema?: useCustomFormSchema<TFieldValues>;
        resetSchema?: boolean;
      } = {
        ignoreSchema: {},
        resetSchema: false,
      },
    ) => {
      if (resetSchema) {
        setSchema(yup.object().shape(newSchema));
      } else {
        setSchema((oldSchema: useCustomFormSchema<TFieldValues>) => {
          if (oldSchema) {
            const excludeIgnoredSchema: useCustomFormSchema<TFieldValues> = {};
            for (const [name, value] of Object.entries(oldSchema.fields)) {
              if (ignoreSchema && !(name in ignoreSchema)) {
                excludeIgnoredSchema[name] = value;
              }
            }

            return yup.object().shape({
              ...excludeIgnoredSchema,
              ...newSchema,
            }) as useCustomFormSchema<TFieldValues>;
          }
          return yup.object().shape(newSchema);
        });
      }
    },
    [],
  );

  const form = useForm<TFieldValues>({
    ...props,
    resolver: schema ? yupResolver<useCustomFormSchema<TFieldValues>>(schema) : undefined,
    mode: 'onChange',
  });

  return {
    ...form,
    updateSchema,
  };
};
