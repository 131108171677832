import { ZodiosPlugin } from '@zodios/core';
import axios from 'axios';
import { SERVER_ERRORS } from '@verifime/utils';

type InterceptorWithTokenProvider = {
  getToken: () => Promise<string | undefined>;
};

export function interceptorWithToken({ getToken }: InterceptorWithTokenProvider): ZodiosPlugin {
  return {
    name: 'interceptorWithToken',
    request: async (api, config) => {
      const token = await getToken();
      if (!token) {
        return config;
      }

      if (config.method !== 'get' && config?.headers?.['Content-Type']) {
        const endpoint = api.find(
          (endpoint) => endpoint.path === config.url && endpoint.method === config.method,
        );
        // This is `multipart` we no need `Content-Type`
        if (endpoint && endpoint.requestFormat === 'form-data') {
          // The config is deeply readonly which means we always get an error
          // while deleting, thus ignore it to eliminate typescript error.
          // @ts-ignore
          delete config.headers['Content-Type'];
        }
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      };
    },
    response: async (api, config, response) => {
      // Due to history reason, we treat the request succeeds when the response data
      // gets validation errors which means that `validationResults` have validation error information
      // in it.
      // We throw this situation with the status code 400 for old apis,
      // new apis will not go into this if statement
      if (response.data?.validationResults?.length) {
        throw { ...response.data, code: 400 };
      }

      return response;
    },
    error: (api, config, error) => {
      // By default the error should be `AxiosError`,
      // we check here just in case it changes somewhere.
      // NOTE:
      // ***********************************************
      // If we changed the fetch method in future,
      // we might highly need to check the error type here as well
      // ***********************************************
      if (axios.isAxiosError(error)) {
        const { response } = error;
        // We rewrite the errors for 5xx
        // to make client error message meaningful
        if (response!.status >= 500) {
          const code: number = response!.status;
          const serverError: any = SERVER_ERRORS[code as keyof typeof SERVER_ERRORS];
          const { exceptionType, error } = response!.data;
          const message = serverError?.[exceptionType] || SERVER_ERRORS.default;
          const serverErrorMessage = error;

          throw { code, message, serverErrorMessage };
        }
      }

      // Other errors (such as 4xx), re-throw them directly
      throw error;
    },
  };
}
