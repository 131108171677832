import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Avatar,
  Badge,
  Box,
  Container,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import Logo from '../Logo';
import Link from 'next/link';
import { TNavItem } from '../types';

const TopBar = ({ navItems, signOut }: { navItems: TNavItem[]; signOut: () => void }) => {
  // const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [isDisplayNotification, setDisplayNotification] = useState<boolean>(false);

  const router = useRouter();
  const theme = useTheme();

  // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  // const handleOpenNotification = () => {
  //   setDisplayNotification(true);
  //   handleCloseUserMenu();
  // };

  // const handleCloseNotification = () => {
  //   setDisplayNotification(false);
  // };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const menuItemStyle = (path: string): object | undefined => {
    return router.pathname === path
      ? {
          borderBottomWidth: 4,
          borderBottomColor: theme.palette.primary.lighter,
          borderBottomStyle: 'solid',
        }
      : undefined;
  };

  return (
    <AppBar position="static">
      <Toolbar disableGutters sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
        <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}>
          <IconButton
            size="large"
            aria-label="Menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {navItems.map((navItem) => (
              <NextLink key={navItem.label} href={navItem.path}>
                <MenuItem onClick={handleCloseNavMenu}>
                  <Typography textAlign="center" sx={menuItemStyle(navItem.path)}>
                    {navItem.label}
                  </Typography>
                </MenuItem>
              </NextLink>
            ))}
          </Menu>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <Link href="/">
            <Logo colorMode="onPrimary" />
          </Link>
        </Box>

        <Typography sx={{ cursor: 'pointer' }} textAlign="center" onClick={signOut}>
          Logout
        </Typography>
        {/* <Tooltip title="Open notifications" sx={{ display: { xs: 'none', sm: 'block' } }}>
              <IconButton size="large" disableRipple onClick={handleOpenNotification}>
                <Badge badgeContent={11} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip> */}

        {/* <Tooltip title="Open user menu" sx={{ flexGrow: 0 }}>
              <IconButton sx={{ p: 2 }} onClick={handleOpenUserMenu} disableRipple>
                {user && (
                  <Avatar
                    alt={`${user.firstName} ${user.lastName}`}
                    src={user.avatar}
                    variant="square"
                  />
                )}
              </IconButton>
            </Tooltip> */}

        {/* {user && (
              <Typography
                variant="subtitle1"
                noWrap
                component="div"
                sx={{ display: { xs: 'none', sm: 'block' } }}
              >
                {user.firstName} {user.lastName}
              </Typography>
            )} */}

        {/* <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem key="Profile" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem key="Account" onClick={handleCloseUserMenu}>
                <Typography textAlign="center">Account</Typography>
              </MenuItem>
              <MenuItem key="Logout" onClick={signOut}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu> */}
      </Toolbar>
    </AppBar>
  );
  {
    /* <Drawer anchor="right" open={isDisplayNotification} onClose={handleCloseNotification}>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Expiring Alert"
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    01/01/2023
                  </Typography>
                  {' - Your identify is expiring soon'}
                </>
              }
            />
          </ListItem>
          <Divider variant="fullWidth" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Renewing your licence"
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    07/01/2023
                  </Typography>
                  {" — It's time to renew your driver licence"}
                </>
              }
            />
          </ListItem>
          <Divider variant="fullWidth" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Renewing your certificate"
              secondary={
                <>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    15/01/2023
                  </Typography>
                  {" — It's time to renew your certificate"}
                </>
              }
            />
          </ListItem>
        </List>
      </Drawer> */
  }
};

export default TopBar;
