import React from 'react';
import { MainLoginState } from './constants';
import { PasswordlessLoginContext } from '../PasswordlessLoginContextProvider';
import { MainLoginContext } from './MainLoginContextProvider';
import EmailInputWithContinueButton from '../../EmailInputWithContinueButton';

export default function EnterYourEmail() {
  const passwordlessLoginContextStore = React.useContext(PasswordlessLoginContext);
  const email = passwordlessLoginContextStore.context.email;
  const setPasswordlessLoginContext = passwordlessLoginContextStore.setContext;
  const setMainLoginContext = React.useContext(MainLoginContext).setContext;

  const handleContinue = () => {
    setMainLoginContext({ state: MainLoginState.LOGIN_METHOD_SELECTION });
  };

  return (
    <EmailInputWithContinueButton
      initialEmail={email}
      onEmailChange={(email) => setPasswordlessLoginContext({ email })}
      onSubmit={handleContinue}
    />
  );
}
