import { InitialCredentialSetup, TInitialCredentialSetupProps } from '@verifime/components';
import { fido2ListCredentials } from 'amazon-cognito-passwordless-auth/fido2';
import { useLocalUserCache, usePasswordless } from './hooks/usePasswordless';
import { otpAssociateCredential, otpHasUserRegistered } from '../otp';
import React from 'react';
import { determineOtpIssuer } from '../utils';

export default function PasswordlessInitialSetup({
  setupComplete,
  noCredentialComponent,
  hidePasskeySetupOption,
}: Pick<TInitialCredentialSetupProps, 'setupComplete' | 'noCredentialComponent' | 'hidePasskeySetupOption'>) {
  const { fido2CreateCredential } = usePasswordless();
  const { updateFidoPreference } = useLocalUserCache();
  const { currentUser } = useLocalUserCache();

  function registerFIDO() {
    return fido2CreateCredential({
      friendlyName: 'Default',
    }).then(() => {
      updateFidoPreference({ useFido: 'YES' });
    });
  }

  function registerOTP(secret: string | (() => string | Promise<string>)): Promise<string> {
    return otpAssociateCredential({ secret });
  }

  async function hasRegisteredFIDO() {
    return await fido2ListCredentials().then(
      (crendentials) => crendentials.authenticators?.length > 0,
    );
  }

  return (
    <InitialCredentialSetup
      otpIssuer={determineOtpIssuer()}
      otpUserDisplayName={currentUser?.email}
      hasRegisteredFIDO={hasRegisteredFIDO}
      hasRegisteredOTP={otpHasUserRegistered}
      registerFIDO={registerFIDO}
      registerOTP={registerOTP}
      setupComplete={setupComplete}
      noCredentialComponent={noCredentialComponent}
      hidePasskeySetupOption={hidePasskeySetupOption}
    />
  );
}
