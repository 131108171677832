import React, { useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Typography, Box, useTheme, Stack, Link } from '@mui/material';
import type { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import tokens, { generateOpacityColor } from '@verifime/design-tokens';
import { ColoredIcon } from '@verifime/components';
import { FileType } from '@verifime/utils';

export type FileSelectorProps = Omit<
  MuiTextFieldProps,
  'onChange' | 'select' | 'type' | 'multiline' | 'defaultValue'
> & {
  isMultiple?: boolean;
  isDisabled?: boolean;
  fileTypes?: FileType[];
  maxFileSizeInMB?: number;
  onChange: (files: File[]) => void;
};

const fileTypeValidator = (file: File, allowedFileTypes: string[]) => {
  if (file?.name && allowedFileTypes.length) {
    const fileExtension = `.${file.name.split('.').pop()}`;
    const isValidType = allowedFileTypes.includes(fileExtension);
    if (!isValidType) {
      return {
        code: "file-invalid-type",
        message: `File type is not supported. Only ${allowedFileTypes.join(', ')} are accepted.`,
      };
    }
    return null;
  }
};

export default function FileDragAndDropSelector({
  label,
  onChange,
  isMultiple,
  isDisabled,
  fileTypes = [],
  maxFileSizeInMB: maxFileSize,
}: FileSelectorProps) {
  const [errorMessage, setErrorMessage] = useState<string>();
  const theme = useTheme();

  const handleDropAccepted = (acceptedFiles: File[]) => {
    setErrorMessage("");
    if (typeof onChange === 'function') {
      onChange(acceptedFiles);
    }
  };

  const handleDropRejected = (fileRejections: FileRejection[]) => {
    const errors = fileRejections.map(({ errors }) => errors.map(e => e.message)).flat();
    setErrorMessage(errors.join('; '));
  };

  const validator = (file: File) => fileTypeValidator(file, fileTypes);

  const maxSize = maxFileSize ? maxFileSize * 1024 * 1024 : undefined;

  const { getRootProps, getInputProps, open } = useDropzone({
    onDropAccepted: handleDropAccepted,
    onDropRejected: handleDropRejected,
    validator,
    disabled: isDisabled,
    maxSize,
    multiple: isMultiple,
    noClick: true,
    noKeyboard: true,
  });

  const info = () => {
    const maxText = maxFileSize ? `max. ${maxFileSize}MB` : '';
    if (fileTypes?.length) {
      const upperCaseExtensions = fileTypes.map(ext => ext.slice(1).toUpperCase());
      const formattedString = upperCaseExtensions.slice(0, -1).join(', ');
      return `${formattedString} or ${upperCaseExtensions[upperCaseExtensions.length - 1]} (${maxText})`;
    }
    return maxText;
  };

  return (
    <Box
      {...getRootProps()}
      height={tokens.size3xs}
      width={tokens.sizeSm}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        border: errorMessage ?
          `1px solid ${theme.palette.error.main}` :
          `1px dashed ${tokens.colorGrey200}`,
        borderRadius: tokens.borderRadiusSm,
        background: errorMessage ? generateOpacityColor(theme.palette.error.main, 0.05) : "none",
        '&:hover': {
          background: isDisabled ?
            "none" :
            generateOpacityColor((errorMessage ? theme.palette.error.main : theme.palette.primary.main), 0.1),
        },
      }}
    >
      <input {...getInputProps()} />
      <Stack alignItems="center" gap={tokens.spacingSm}>
        <ColoredIcon
          iconName="uploadFileIcon"
          iconSize="medium"
          color={isDisabled ? 'disabled' :
            (errorMessage ? "error" : "primary")} />
        <Typography>
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              open();
            }}
            color={isDisabled ? theme.palette.action.disabled : "primary"}>{label ? label : "Click to upload"}</Link> or drag and drop
        </Typography>
        <Typography variant="body2" color={errorMessage ? "error" : "textSecondary"}>
          {errorMessage ? errorMessage : info()}
        </Typography>
      </Stack>
    </Box>
  );
}
