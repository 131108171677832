import { Box, alpha, useTheme } from '@mui/material';
import { ErrorTypes } from '@regulaforensics/vp-frontend-document-components';
import {
  FaceLivenessDetailType,
  FaceLivenessWebComponent,
  IFaceLiveness,
} from '@regulaforensics/vp-frontend-face-components';
import { useMobile } from '@verifime/utils';
import React, { DetailedHTMLProps, HTMLAttributes, useEffect, useRef, useState } from 'react';
import CheckError from './CheckError';
import CheckSuccess from './CheckSuccess';
import NoCamera from './NoCamera';
import StartScreen from './StartScreen';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'face-liveness': DetailedHTMLProps<
        IFaceLiveness & HTMLAttributes<FaceLivenessWebComponent>,
        FaceLivenessWebComponent
      >;
    }
  }
}

export default function FaceLiveness({
  url,
  sessionId,
  token,
  onFinish,
  onSuccess,
}: Readonly<{
  url: string;
  sessionId: string;
  token: string;
  // Finished the face check
  // status: 0 if the liveness is confirmed, 1 if not.
  onFinish?: (transactionId: string, status: number) => void;
  // The face check succeedeed
  onSuccess?: () => void;
}>) {
  const [isOpen, setIsOpen] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const componentRef = useRef<FaceLivenessWebComponent>(null);
  const theme = useTheme();
  const isMobile = useMobile();

  useEffect(() => {
    import('@regulaforensics/vp-frontend-face-components');
  }, []);

  const listener = (data: CustomEvent<FaceLivenessDetailType>) => {
    if (data.detail.action === 'PROCESS_FINISHED') {
      const { status: dataStatus, response, reason } = data.detail.data;
      // Errors happen
      if (dataStatus === 0) {
        setErrorType(reason as ErrorTypes);
      }

      if (dataStatus === 1 && response) {
        const { status, transactionId } = data.detail.data.response;
        onFinish?.(transactionId, status);
        // success
        if (status === 0) {
          onSuccess?.();
          setIsSuccess(true);
        }
      }
    }

    if (data.detail?.action === 'CLOSE' || data.detail?.action === 'RETRY_COUNTER_EXCEEDED') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen && componentRef.current) {
      componentRef.current.settings = {
        url,
        headers: {
          Authorization: 'Bearer ' + token,
        },
        videoRecording: true,
        copyright: false,
        tag: sessionId,
        startScreen: false,
        finishScreen: true,
        customization: {
          retryScreenRetryButtonBackground: theme.palette.primary.main,
          retryScreenRetryButtonBackgroundHover: alpha(theme.palette.primary.main, 0.8),
        },
      };

      // To set the face-liveness 100% width
      setTimeout(() => {
        const divContainer = componentRef.current.shadowRoot.querySelector('div');
        divContainer?.setAttribute('style', 'width:100%');
      });
    }
  }, [isOpen]);

  useEffect(() => {
    const containerCurrent = containerRef.current;

    if (!containerCurrent) return;

    containerCurrent.addEventListener('face-liveness', listener);

    return () => {
      containerCurrent.removeEventListener('face-liveness', listener);
    };
  }, []);

  const FaceCheckComponent = () => {
    if (['CAMERA_PERMISSION_DENIED', 'NO_CAMERA', 'CAMERA_UNKNOWN_ERROR'].includes(errorType)) {
      return <NoCamera />;
    }

    if (['WASM_ERROR', 'UNKNOWN_ERROR', 'NOT_SUPPORTED', 'CONNECTION_ERROR'].includes(errorType)) {
      return <CheckError />;
    }

    if (isSuccess) {
      return <CheckSuccess />;
    }

    if (isOpen) {
      return (
        <face-liveness
          ref={componentRef}
          style={
            isMobile
              ? {
                  height: '100vh',
                  position: 'absolute',
                  zIndex: 2,
                  top: 0,
                  left: 0,
                  right: 0,
                  width: '100vw',
                  bottom: 0,
                }
              : {}
          }
        ></face-liveness>
      );
    }
  };

  return (
    <Box ref={containerRef}>
      {isOpen ? <FaceCheckComponent /> : <StartScreen onButtonClick={() => setIsOpen(true)} />}
    </Box>
  );
}
