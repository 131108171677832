import CircularProgress from '@mui/material/CircularProgress';
import { OTPSettings as OTPSettingsComponent } from '@verifime/components';
import { otpHasUserRegistered } from '../otp';
import React from 'react';
import SetupAuthenticatorAppDrawer from './SetupAuthenticatorAppDrawer';

export default function OTPSettings({ openSetupDialog = false }: { openSetupDialog?: boolean }) {
  const [checkingOtpRegistration, setCheckingOtpRegistration] = React.useState<boolean>(true);
  const [userHasRegisteredOtp, setUserHasRegisteredOtp] = React.useState<boolean>(false);
  const [openOtpSetupDialog, setOpenOtpSetupDialog] = React.useState<boolean>(openSetupDialog);
  const [error, setError] = React.useState<Error | undefined>(undefined);

  const refreshOtpRegistration = React.useCallback(() => {
    setCheckingOtpRegistration(true);
    otpHasUserRegistered()
      .then((otpRegistered) => {
        setUserHasRegisteredOtp(otpRegistered);
      })
      .catch(setError)
      .finally(() => {
        setCheckingOtpRegistration(false);
      });
  }, [setUserHasRegisteredOtp, setError]);

  const handleClickOpen = () => {
    setOpenOtpSetupDialog(true);
  };

  const handleCloseSetupAuthenticatorAppDrawer = () => {
    setOpenOtpSetupDialog(false);
    refreshOtpRegistration();
  };

  React.useEffect(() => {
    refreshOtpRegistration();
  }, [refreshOtpRegistration]);

  return (
    <>
      <SetupAuthenticatorAppDrawer
        open={openOtpSetupDialog}
        onClose={handleCloseSetupAuthenticatorAppDrawer}
      />
      {checkingOtpRegistration ? (
        <CircularProgress />
      ) : (
        <OTPSettingsComponent
          error={error}
          hasOtp={userHasRegisteredOtp}
          handleOtpSetup={handleClickOpen}
        />
      )}
    </>
  );
}
