import { Paper, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import React from 'react';
import NoCameraIcon from './noCamera.svg';

export default function NoCamera() {
  return (
    <Paper>
      <Stack
        paddingX={tokens.spacingBase}
        paddingY={tokens.spacingLg}
        borderRadius={tokens.borderRadiusSm}
        gap={tokens.spacingBase}
        alignItems="center"
      >
        <NoCameraIcon />
        <Typography variant="h6">Unable to detect a camera</Typography>
        <Typography variant="body2" color="textSecondary">
          Please make sure your device has a working camera and that your browser has access to it.
          You can also continue this submission by signing in on a different device.
        </Typography>
      </Stack>
    </Paper>
  );
}
