import { Box, Button, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import {
  ADDRESS_PREFIX_SEPARATOR,
  TAddress,
  TCountryCode,
  TObject,
  getAddressString,
  stringUtils,
} from '@verifime/utils';
import deepClone from 'lodash/cloneDeep';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormSetValue,
  UseFormTrigger,
  useWatch,
} from 'react-hook-form';
import FlexBox from '../../FlexBox';
import ResponsiveBox from '../../ResponsiveBox';
import { createFormFieldStack } from '../FormFieldsContainerSupportDisplayVariant';
import GenerateFormFields from '../GenerateFormFields';
import { TFieldsOperations } from '../GenerateFormFields_New';
import {
  DisplayVariant,
  TFormFieldsAndRules,
  getFieldsNamesFromFieldsAndRules,
  getFieldsPrefixFromFieldsAndRules,
  resetFields,
} from '../utils';
import { countryAddressFormatter } from './utils';

export type TSameAsAddress = { fieldsAndRules: TFormFieldsAndRules; label: string };
export type TAddressProps = {
  countryCode: TCountryCode;
  addressFieldsAndRules: TFormFieldsAndRules;
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  defaultValues: Record<string, any>;
  onPlaceSelected?: (place: any) => void;
  label?: string;
  placeholder?: string;
  dataCy?: string;
  inputsFieldsOperations?: TFieldsOperations;
  googleMapsApiKey?: string;
  sameAsAddress?: TSameAsAddress;
  isAutocomplete?: boolean;
  variant?: DisplayVariant;
};

export default function Address({
  countryCode,
  addressFieldsAndRules,
  control,
  errors,
  trigger,
  setValue,
  defaultValues,
  onPlaceSelected,
  label = '',
  placeholder,
  dataCy,
  inputsFieldsOperations,
  googleMapsApiKey,
  sameAsAddress,
  isAutocomplete = true,
  variant = DisplayVariant.MULTIPLE_COLUMNS,
}: TAddressProps) {
  const [isAutoCompleteAddressEntry, setIsAutoCompleteAddressEntry] = useState(isAutocomplete);
  const [isError, setIsError] = useState(false);
  const [isSameAsChecked, setIsSameAsChecked] = useState(false);

  const sameAsAddressClickedAddressFieldsAndRules = useRef(
    disabledFieldsInfo(addressFieldsAndRules),
  );

  const fieldsNamesOfSameAsAddress = useMemo(
    () => getFieldsNamesFromFieldsAndRules(sameAsAddress?.fieldsAndRules) ?? [''],
    [sameAsAddress?.fieldsAndRules],
  );

  const watchedSameAsAddressResults = useWatch({
    control,
    name: fieldsNamesOfSameAsAddress,
  });

  const requiredFieldsNames: string[] = useMemo(() => {
    return Object.entries(addressFieldsAndRules || {})
      .map(([_, fieldInfo]) => {
        // A field marked as showRequiredAsterisk = true,
        // means that this field is required
        if (fieldInfo?.showRequiredAsterisk) {
          return fieldInfo.fieldName;
        }
      })
      .filter(Boolean);
  }, [addressFieldsAndRules]);

  useEffect(() => {
    const isError = requiredFieldsNames.some((fieldName) => fieldName in errors);
    setIsError(isError);
  }, [errors, requiredFieldsNames]);

  const onPlaceAutoCompleted = useCallback(
    (place: any) => {
      const formattedAddress = countryAddressFormatter[countryCode](place.address_components);
      const addressFieldsEntries = Object.entries(formattedAddress)
        .map(([k, v]) => {
          if (!addressFieldsAndRules[k]?.fieldName) {
            return null;
          } else {
            return [addressFieldsAndRules[k].fieldName, v];
          }
        })
        .filter(Boolean);
      const addressFields = Object.fromEntries(addressFieldsEntries);
      Object.entries(addressFields).forEach(([k, v]) => {
        setValue(k, v);
      });
    },
    [addressFieldsAndRules, setValue, countryCode],
  );

  const { ref } = usePlacesWidget({
    apiKey: googleMapsApiKey,
    onPlaceSelected: (place: any) => {
      onPlaceAutoCompleted(place);
      if (typeof onPlaceSelected === 'function') {
        onPlaceSelected(place);
      }
      setIsError(false);
      // Trigger form validation immediately to eliminate manual fields error prompt
      trigger(requiredFieldsNames);
    },
    inputAutocompleteValue: 'address',
    options: {
      componentRestrictions: { country: countryCode },
      types: ['address'],
      fields: ['address_components', 'formatted_address'],
    },
  });

  useEffect(() => {
    const input: HTMLInputElement = ref.current;
    if (input) {
      if (!input.value) {
        // TODO: Need to support addresses of all countries,
        // But when update only NZ and AU available for now,
        // thus here just fill the auto compolete input for those two countries
        const countryField = addressFieldsAndRules?.countryCode;
        if (
          Object.keys(defaultValues || {}).length > 0 &&
          defaultValues[countryField?.fieldName] === countryCode
        ) {
          let defaultWholeAddress = '';
          defaultWholeAddress += defaultValues[addressFieldsAndRules.unitNumber.fieldName]
            ? defaultValues[addressFieldsAndRules.unitNumber.fieldName] + '/'
            : '';
          defaultWholeAddress += defaultValues[addressFieldsAndRules.streetNumber.fieldName] + ' ';
          defaultWholeAddress += defaultValues[addressFieldsAndRules.streetName.fieldName] + ' ';
          defaultWholeAddress += defaultValues[addressFieldsAndRules.streetType.fieldName] + ', ';
          defaultWholeAddress += defaultValues[addressFieldsAndRules.suburb.fieldName] + ', ';
          defaultWholeAddress += countryCode;

          input.value = defaultWholeAddress;
        }
      }
    }
  }, [countryCode, defaultValues, addressFieldsAndRules, ref]);

  useEffect(() => {
    if (Object.keys(defaultValues || {}).length > 0) {
      Object.entries(defaultValues).forEach(([k, v]) => {
        if (v) {
          setValue(k, v);
        }
      });
    }

    // Force Reset all address fields and address inputs
    if (!defaultValues) {
      // Reset all address fields, we don't consider country field in Address component
      resetFields(setValue, getFieldsNamesFromFieldsAndRules(addressFieldsAndRules), {
        excludes: [addressFieldsAndRules?.countryCode?.fieldName],
      });

      if (ref.current) {
        //@ts-ignore
        ref.current.value = '';
      }
    }
  }, [defaultValues, addressFieldsAndRules]);

  useEffect(() => {
    if (!isAutoCompleteAddressEntry) {
      if (addressFieldsAndRules?.countryCode?.fieldName && countryCode) {
        setValue(addressFieldsAndRules?.countryCode?.fieldName, countryCode);
      }
    }
  }, [isAutoCompleteAddressEntry, countryCode]);

  useEffect(() => {
    if (!isSameAsChecked) {
      return;
    }

    const addressPrefix = getFieldsPrefixFromFieldsAndRules(addressFieldsAndRules);
    const addressFieldsAndValues = fieldsNamesOfSameAsAddress.reduce(
      (tally: TObject, name, index) => {
        const value = watchedSameAsAddressResults[index];
        const suffix = name.split(ADDRESS_PREFIX_SEPARATOR)[1];
        const fieldName = addressPrefix + ADDRESS_PREFIX_SEPARATOR + suffix;
        tally[fieldName] = value ?? '';
        return tally;
      },
      {},
    );

    Object.entries(addressFieldsAndValues).forEach(([k, v]) => {
      setValue(k, v);
    });

    const address = Object.entries(addressFieldsAndValues).reduce(
      (tally: TObject, [name, value]) => {
        const k = stringUtils.lowerCaseFirstLetter(name.split(ADDRESS_PREFIX_SEPARATOR)[1]);

        if (value) {
          tally[k] = value;
        }
        return tally;
      },
      {},
    );

    if (Object.keys(address).length > 0) {
      const addressString = getAddressString(address as TAddress);

      const input: HTMLInputElement = ref.current;
      if (input) {
        input.value = addressString;
      }

      setIsError(false);
      trigger(Object.keys(addressFieldsAndValues));
    }
  }, [
    isSameAsChecked,
    watchedSameAsAddressResults,
    fieldsNamesOfSameAsAddress,
    addressFieldsAndRules,
  ]);

  if (Object.keys(addressFieldsAndRules).length < 1) {
    return null;
  }

  const AddressRowContainerComponent = React.useCallback(
    createFormFieldStack(variant, {
      gap: tokens.spacingBase,
    }),
    [createFormFieldStack, variant, tokens.spacingBase],
  );

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', my: '.5rem' }}
      data-cy={`address-${countryCode}`}
      key={countryCode}
    >
      <FlexBox
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: `${tokens.spacingXs} 0`,
        }}
      >
        <Typography variant="h6">{label}</Typography>
        {isAutocomplete && (
          <Button
            variant="text"
            onClick={() => setIsAutoCompleteAddressEntry(!isAutoCompleteAddressEntry)}
            data-cy="button-switch-address-auto-complete"
            disabled={isSameAsChecked}
          >
            {isAutoCompleteAddressEntry ? 'ENTER MANUALLY' : 'AUTO COMPLETE'}
          </Button>
        )}
      </FlexBox>

      {Object.keys(sameAsAddress?.fieldsAndRules || {}).length > 0 && (
        <Box>
          <FormControlLabel
            label={`Same as ${stringUtils.lowerCaseFirstLetter(sameAsAddress.label)}`}
            control={<Checkbox onChange={(e) => setIsSameAsChecked(e.target.checked)} />}
          />
        </Box>
      )}

      {/** To make sure inputs to be memoried while switching  */}
      <ResponsiveBox
        sx={{
          display: isAutoCompleteAddressEntry ? 'block' : 'none',
        }}
      >
        <TextField
          fullWidth
          color="primary"
          variant="outlined"
          inputRef={ref}
          placeholder={placeholder ?? 'Please enter your address'}
          data-cy={dataCy || 'text-address-auto-complete'}
          error={isError}
          autoComplete="off"
          inputProps={{
            autoComplete: 'off',
            'aria-autocomplete': 'none',
          }}
          onChange={(e) => {
            if (!e.target.value) {
              // Reset all address fields, we don't consider country field in Address component
              resetFields(setValue, getFieldsNamesFromFieldsAndRules(addressFieldsAndRules), {
                excludes: [addressFieldsAndRules?.countryCode?.fieldName],
              });
            }
          }}
          disabled={isSameAsChecked}
          helperText={isError ? 'Please enter your address' : ''}
        />
      </ResponsiveBox>
      <div
        style={{
          display: isAutoCompleteAddressEntry ? 'none' : 'block',
        }}
      >
        <GenerateFormFields
          formFieldsAndRules={
            isSameAsChecked
              ? sameAsAddressClickedAddressFieldsAndRules.current
              : addressFieldsAndRules
          }
          control={control}
          errors={errors}
          fieldsOperations={inputsFieldsOperations}
          ContainerComponent={AddressRowContainerComponent}
        />
      </div>
    </Box>
  );
}

function disabledFieldsInfo(addressFieldsAndRules: TFormFieldsAndRules) {
  const clonedAddressFieldsAndRules = deepClone(addressFieldsAndRules);
  for (const [_, fieldInfo] of Object.entries(clonedAddressFieldsAndRules)) {
    fieldInfo.disabled = true;
  }
  return clonedAddressFieldsAndRules;
}
