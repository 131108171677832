import { Control, Controller, FieldErrorsImpl, FieldValues } from 'react-hook-form';
import { Box, Typography } from '@mui/material';
import { TFromFieldInfo_New } from './utils';
import TextEditor from '../TextEditor';

const FormTextEditor = ({
  fieldInfo,
  control,
  errors,
  required,
  disabled,
}: {
  fieldInfo: TFromFieldInfo_New;
  control: Control<FieldValues>;
  errors: Partial<
    FieldErrorsImpl<{
      [x: string]: any;
    }>
  >;
  required: boolean;
  disabled?: boolean;
}) => (
  <Controller
    name={fieldInfo.fieldName}
    control={control}
    render={({ field, formState }) => {
      const onEditorStateChange = (editorState: any) => {
        field.onChange(editorState);
      };

      return (
        <Box style={{ width: '100%' }}>
          <Typography variant="h6">
            {fieldInfo.label} {required ? '*' : ''}
          </Typography>
          <TextEditor
            initialValue={formState.defaultValues[fieldInfo.fieldName]}
            onTextChange={onEditorStateChange}
            errorMessage={(errors[fieldInfo.fieldName]?.message as string) ?? ''}
            readOnly={disabled}
          />
        </Box>
      );
    }}
  />
);

export default FormTextEditor;
