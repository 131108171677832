import { useState } from 'react';
import { Stack } from '@mui/material';
import IdentityCard from './IdentityCard';
import tokens from '@verifime/design-tokens';
import { IdentityStatus, IdentityType, TIdentity } from '../types';
import CreateNewidentity from './CreateNewIdentity';
import { SelectItemDrawer } from '../SelectItemDrawer';
import { TItemType } from '../SelectItemDrawer/SelectItemDrawer';

export interface IdentityCardsProps {
  identityTypes: TItemType[];
  allIdentity: TIdentity[];
  onClickIdentity: ({
    index,
    id,
    type,
    status,
  }: {
    index: number;
  } & Omit<TIdentity, 'name'>) => void;
}

export default function IdentityCards({
  allIdentity,
  identityTypes,
  onClickIdentity,
}: IdentityCardsProps) {
  const [isOpenSelectIdentityType, setOpenSelectIdentityType] = useState(false);

  const handleAddNewIdentity = () => {
    setOpenSelectIdentityType(true);
  };

  const canCreateNewIdentity =
    allIdentity.filter(
      (identity) =>
        identity.type === IdentityType.INDIVIDUAL &&
        [IdentityStatus.VERIFIED, IdentityStatus.IN_PROGRESS, IdentityStatus.REJECTED].includes(
          identity.status,
        ),
    ).length > 0;

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-start"
        justifyItems="flex-start"
        gap={tokens.spacingLg}
        flexWrap="wrap"
      >
        {allIdentity.map((identity, index) => (
          <IdentityCard
            {...identity}
            key={index}
            onClick={() =>
              onClickIdentity({
                index,
                id: identity.id,
                type: identity.type,
                status: identity.status,
              })
            }
          />
        ))}
        {canCreateNewIdentity && <CreateNewidentity onAdd={handleAddNewIdentity} />}
      </Stack>

      <SelectItemDrawer
        title="Select identity type"
        options={identityTypes}
        open={isOpenSelectIdentityType}
        onClose={() => setOpenSelectIdentityType(false)}
      />
    </>
  );
}
