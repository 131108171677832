import {
  FormControlLabel,
  SwitchProps,
  FormHelperText,
  FormControl,
  useTheme,
  Switch,
} from '@mui/material';
import { Control, Controller, FieldError } from 'react-hook-form';
import { formFieldLabel } from '@verifime/components';
import { ReactNode } from 'react';

type TFormSwitchProps = {
  name: string;
  control: Control;
  label: ReactNode | string;
  required?: boolean;
  error?: FieldError;
  onSwitchChange?: (isChecked: boolean) => void;
} & SwitchProps;

export default function FormSwitch({
  name,
  control,
  label,
  required,
  defaultChecked,
  error,
  onSwitchChange,
  ...props
}: TFormSwitchProps) {
  const theme = useTheme();

  const requiredLabel: ReactNode = formFieldLabel(label, required);

  return (
    <FormControl>
      <FormControlLabel
        sx={{
          opacity: '0.8',
          color: error ? theme.palette.error.main : '',
          ...props.sx,
        }}
        control={
          <Controller
            defaultValue={defaultChecked}
            name={name}
            control={control}
            render={({ field }) => {
              return (
                <Switch
                  sx={{
                    color: error ? theme.palette.error.main : '',
                  }}
                  {...field}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    field.onChange(isChecked);
                    onSwitchChange?.(isChecked);
                  }}
                  {...props}
                  checked={field.value === true}
                />
              );
            }}
          />
        }
        label={requiredLabel}
      />
      {error && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  );
}
