import capitalize from 'lodash/capitalize';

/**
 *
 * Generate random string
 *
 * @param type available values are `number` and `string`, default is `number`.
 * 1. if the `number` type given, the generated string are all number
 * 2. if the `string` type given, the generated string are mix of number and letters
 *
 * @param length The length of generated string, default length is 5.
 */
export function getRandomString(type: 'number' | 'string', length = 5) {
  const radix = type === 'number' ? 10 : 36;
  return (Math.random() + 1).toString(radix).slice(2, length + 2);
}

export function generateCamelCaseString(str1: string, ...restStr: string[]) {
  return str1.concat(restStr.map((str) => str.replace(/^./, (c) => c.toUpperCase())).join(''));
}

export function lowerCaseFirstLetter(str: string) {
  return str.replace(/^./, (c) => c.toLowerCase());
}

export function splitCamelCase(value: string) {
  return value.replace(/([A-Z])/g, ' $1').replace(/^./, capitalize);
}

export function ellipsis(
  str: string,
  position: 'start' | 'middle' | 'end' = 'end',
  firstCharCount = 15,
  endCarCount = 10,
  dotCount = 3,
) {
  // No Ellipsis needed
  if (str.length < firstCharCount + endCarCount) {
    return str;
  }
  const firstPortion = str.slice(0, firstCharCount);
  const endPortion = str.slice(-endCarCount);
  const dots = '.'.repeat(dotCount);

  if (position === 'start') {
    return dots + endPortion;
  }

  if (position === 'middle') {
    return firstPortion + dots + endPortion;
  }

  if (position === 'end') {
    return firstPortion + dots;
  }
  return str;
}

/**
 * This is a basic version for now, just try to get extension from url.
 *
 * Return lowercase extension if can get it from url, otherwise return null.
 *
 * @param url String formatted url
 * @returns expected lowercase extension (such as `jpg`) or null
 */
// TODO:
// If we are going to get extension from a file,
// then we should first try to fetch the file, then get the extension from it.
export function getFileExtensionFromUrl(url: string) {
  if (!url) {
    return null;
  }
  const extension = url.slice(url.lastIndexOf('.') + 1);
  if (!extension) {
    return null;
  }
  return extension.toLowerCase();
}
