import { Typography, TypographyProps } from '@mui/material';

const Title = ({ sx, children, ...otherProps }: TypographyProps) => {
  return (
    <Typography
      data-cy="text-title"
      variant="subtitle1"
      sx={{
        textTransform: 'capitalize',
        fontWeight: '700',
        color: (theme) => theme.palette.primary.lighter,
        lineHeight: 1,
        ...sx,
      }}
      {...otherProps}
    >
      {children}
    </Typography>
  );
};

export default Title;
