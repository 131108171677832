import { addressFieldsPrefix } from '@verifime/utils';
import { TFormFieldsAndRules_New } from '../Form';
import useAddEntity from './useAddEntity';
import { AddressFieldsAndRulesName, individualFormFieldsAndRules } from './utils';

type TUseAddEntity = Parameters<typeof useAddEntity>[0];

export default function useAddIndividualEntity({
  extraFormFieldsAndRules = {},
  ...props
}: {
  extraFormFieldsAndRules?: TFormFieldsAndRules_New;
} & Omit<TUseAddEntity, 'initialFormFieldsAndRules' | 'initialAddresses'>) {
  return useAddEntity({
    initialFormFieldsAndRules: {
      ...individualFormFieldsAndRules,
      ...extraFormFieldsAndRules,
    },
    initialAddresses: [
      {
        renderType: 'AddressWithCountrySelect',
        fieldsAndRulesName: AddressFieldsAndRulesName.addressFieldsAndRules,
        label: '',
        inputLabel: 'Residential Address',
        inputPlaceholder: 'Address',
        fieldsPrefix: addressFieldsPrefix,
      },
    ],
    ...props,
  });
}
