import React from 'react';
import { LoginContainer } from './common/LoginContainer';
import { SignInState } from './constants';
import MainLogin, { TMainLoginProps } from './MainLogin';
import { WelcomeToVerifiMeTitle } from './common/LoginTitle';
import ValidatingSignInStatus from './ValidatingSignInStatus';
import { SigningYouIn } from './SigningYouIn';
import PasswordlessLoginContextProvider from './PasswordlessLoginContextProvider';
import SignInLinkRequested from './SignInLinkRequested';

export default function PasswordlessLogin({
  ...props
}: {
  children?: React.ReactNode;
  signInState: SignInState;
  signInRedirect?: () => void;
} & TMainLoginProps) {
  const { signInState, signInRedirect } = props;

  if (signInState === SignInState.SIGNED_IN) {
    if (typeof signInRedirect === 'function') {
      signInRedirect();
    }
  }

  return (
    <PasswordlessLoginContextProvider lastSignedInEmail={props.lastSignedInEmail}>
      <LoginContainer>
        {SignInState.NOT_SIGNED_IN === signInState && (
          <>
            <WelcomeToVerifiMeTitle />
            <MainLogin {...props} />
          </>
        )}
        {SignInState.SIGNIN_LINK_REQUESTED === signInState && (
          <>
            <WelcomeToVerifiMeTitle />
            <SignInLinkRequested />
          </>
        )}
        {SignInState.VALIDATE_SIGN_IN_STATUS === signInState && (
          <>
            <WelcomeToVerifiMeTitle />
            <ValidatingSignInStatus />
          </>
        )}
        {SignInState.SIGNING_YOU_IN === signInState && (
          <>
            <WelcomeToVerifiMeTitle />
            <SigningYouIn />
          </>
        )}
        {SignInState.SIGNED_IN === signInState && props.children && (
          <>
            <WelcomeToVerifiMeTitle />
            {props.children}
          </>
        )}
      </LoginContainer>
    </PasswordlessLoginContextProvider>
  );
}
