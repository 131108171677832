import React, { useState, useCallback, Fragment } from 'react';
import ConfirmContext from './ConfirmContext';
import ConfirmationDialog from './ConfirmationDialog';
import { ConfirmOptions, ConfirmProviderProps } from './index';

const DEFAULT_OPTIONS: ConfirmOptions = {
  title: 'Are you sure?',
  description: '',
  content: null,
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  dialogProps: {},
  dialogActionsProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {},
  titleProps: {},
  contentProps: {},
  allowClose: true,
  hideCancelButton: false,
  buttonOrder: ['cancel', 'confirm'],
  inputbox: false,
  defaultInputboxValue: '',
  inputboxTextFieldProps: {},
  inputboxValueRequired: true,
};

const buildOptions = (defaultOptions?: ConfirmOptions, options?: ConfirmOptions) => {
  const dialogProps = {
    ...(defaultOptions?.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options?.dialogProps || {}),
  };
  const dialogActionsProps = {
    ...(defaultOptions?.dialogActionsProps || DEFAULT_OPTIONS.dialogActionsProps),
    ...(options?.dialogActionsProps || {}),
  };
  const confirmationButtonProps = {
    ...(defaultOptions?.confirmationButtonProps || DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options?.confirmationButtonProps || {}),
  };
  const cancellationButtonProps = {
    ...(defaultOptions?.cancellationButtonProps || DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options?.cancellationButtonProps || {}),
  };
  const titleProps = {
    ...(defaultOptions?.titleProps || DEFAULT_OPTIONS.titleProps),
    ...(options?.titleProps || {}),
  };
  const contentProps = {
    ...(defaultOptions?.contentProps || DEFAULT_OPTIONS.contentProps),
    ...(options?.contentProps || {}),
  };
  const inputboxTextFieldProps = {
    ...(defaultOptions?.inputboxTextFieldProps || DEFAULT_OPTIONS.inputboxTextFieldProps),
    ...(options?.inputboxTextFieldProps || {}),
  };

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    dialogActionsProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    inputboxTextFieldProps,
  };
};

export default function ConfirmProvider({ children, defaultOptions }: ConfirmProviderProps) {
  const [options, setOptions] = useState({});
  const [resolveReject, setResolveReject] = useState([]);
  const [key, setKey] = useState(0);
  const [resolve, reject] = resolveReject;

  const confirm = useCallback((options = {}) => {
    return new Promise<string | undefined>((resolve, reject) => {
      setKey((key) => key + 1);
      setOptions(options);
      setResolveReject([resolve, reject]);
    });
  }, []);

  const handleClose = useCallback(() => {
    setResolveReject([]);
  }, []);

  const handleCancel = useCallback(() => {
    if (reject) {
      reject();
      handleClose();
    }
  }, [reject, handleClose]);

  const handleConfirm = useCallback(
    (inputboxValue?: string) => {
      if (resolve) {
        resolve(inputboxValue);
        handleClose();
      }
    },
    [resolve, handleClose],
  );

  return (
    <Fragment>
      <ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
      <ConfirmationDialog
        key={key}
        open={resolveReject.length === 2}
        options={buildOptions(defaultOptions, options)}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </Fragment>
  );
}
