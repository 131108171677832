import { SERVER_ERRORS } from '@verifime/utils';

// Intercept api response to either return data json when response is in the range of 200-299 or throw exception for non-200 range response.
// The thrown exception is supposed to be captured by api comsumer component to display global error.
export const responseInterceptor = async (url: string, token: string | null, options: any) => {
  // not all request are authenticated, in some cases we don't need the token specified.
  if (!token && options.tokenRequired) {
    return;
  }

  const { headers: customHeaders, isMultipart, ...otherOptions } = options;

  const defaultHeaders: any = {
    'Content-Type': 'application/json',
  };

  if (token) {
    defaultHeaders['Authorization'] = 'Bearer ' + token;
  }

  if (isMultipart) {
    delete defaultHeaders['Content-Type'];
  }

  // Override default headers if custom headers exist in optons
  let headers = { ...defaultHeaders };
  if (customHeaders) {
    headers = { ...defaultHeaders, ...customHeaders };
  }

  const response = await fetch(process.env.NEXT_PUBLIC_API_HOST + url, {
    headers,
    ...otherOptions,
  }).catch((err) => {
    if (err.name === 'AbortError') {
      return { json: () => {}, ok: true, status: 200 };
    }
  });

  // Because currently our DELETE api endpoint return nothing (the response body is `null`),
  // thus when we trying to get json, we'll get the error `Unexpected end of input`.
  let responseData;
  try {
    responseData = await response?.json();
  } catch (err) {
    responseData = {};
  }

  // Non-200 range, throw exception
  if (!response.ok) {
    const code: number = response.status;
    const serverError: any = SERVER_ERRORS[code as keyof typeof SERVER_ERRORS];

    const message = serverError?.[responseData.exceptionType] || SERVER_ERRORS.default;
    const serverErrorMessage = responseData.error;

    throw { code, message, serverErrorMessage };
  }

  return responseData;
};
