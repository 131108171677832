import CopyIcon from '@mui/icons-material/ContentCopy';
import { Tooltip, TooltipProps, Typography, TypographyProps, alpha } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { useMobile } from '@verifime/utils';
import React, { ReactNode, useState } from 'react';
import FlexBox from './FlexBox';

enum CopyState {
  NORMAL,
  HOVER,
  COPIED,
}

export default function CopyableText({
  text,
  icon,
  notAvailableText = 'Not Available',
  textVariant = 'body2',
  textColor = 'textPrimary',
  tooltipPlacement = 'right',
}: Readonly<{
  text: ReactNode;
  icon?: JSX.Element;
  notAvailableText?: ReactNode;
  textColor?: TypographyProps['color'];
  textVariant?: TypographyProps['variant'];
  tooltipPlacement?: TooltipProps['placement'];
}>) {
  const isMobile = useMobile();
  const [copyState, setCopyState] = useState<CopyState>(
    isMobile ? CopyState.HOVER : CopyState.NORMAL,
  );

  return (
    <FlexBox
      onMouseOver={() => text && setCopyState(CopyState.HOVER)}
      onMouseOut={() => (isMobile ? setCopyState(CopyState.HOVER) : setCopyState(CopyState.NORMAL))}
      onClick={async () => {
        if (text && navigator.clipboard) {
          await navigator.clipboard.writeText(text.toString());
          setCopyState(CopyState.COPIED);
        }
      }}
    >
      <FlexBox
        sx={{
          py: tokens.spacingSm,
          px: tokens.spacingXs,
          my: tokens.spacingXs,
          gap: tokens.spacingXs,
          borderRadius: tokens.borderRadiusSm,
          backgroundColor: (theme) =>
            copyState === CopyState.HOVER ? alpha(theme.palette.primary.main, 0.05) : 'none',
          '&:hover': {
            cursor: () => (copyState === CopyState.HOVER ? 'pointer' : 'default'),
          },
        }}
        alignItems="center"
      >
        {icon}
        <Tooltip
          title="copied to clipboard"
          open={copyState === CopyState.COPIED}
          placement={tooltipPlacement}
        >
          <Typography
            variant={textVariant}
            color={textColor}
            sx={{ overflowWrap: 'anywhere', whiteSpace: 'pre-wrap' }}
          >
            {text || notAvailableText}
          </Typography>
        </Tooltip>
        {copyState === CopyState.HOVER && <CopyIcon fontSize="inherit" />}
      </FlexBox>
    </FlexBox>
  );
}
