import { PasskeySettings as PasskeySettingsComponent } from '@verifime/components';
import { usePasswordless } from './hooks/usePasswordless';

export default function PasskeySettings({openSetupDialog = false} : {
  openSetupDialog?: boolean;
}) {
  const {
    fido2CreateCredential,
    fido2Credentials,
  } = usePasswordless();

  return (
    <PasskeySettingsComponent
      credentials={fido2Credentials ?? []}
      handleAddNew={(newName: () => Promise<string>) =>
        fido2CreateCredential({ friendlyName: newName }).then(() => {})
      }
      handleRemove={(index) => fido2Credentials![index].delete()}
      handleRename={(index, newName) => fido2Credentials![index].update({ friendlyName: newName })}
      openSetupDialog={openSetupDialog}
    />
  );
}
