import { format, isValid, subDays } from 'date-fns';
import * as yup from 'yup';
import { COUNTRY_STATES, SUPPORTED_COUNTRY_MAP, SupportedCountryCode } from './constants';
import { parseDateString } from './date';

type TLengthConstraints = {
  min: number;
  max: number;
};

type TValidationConstraints = TLengthConstraints & {
  dataType: 'number' | 'string';
};

type TRequiredMessage = { requiredMessage?: string };

const defaultLengthConstraints = { min: 1, max: Number.MAX_VALUE };

// Field validation error messages
export const FIELD_VALIDATION_REQUIRED = 'Required';
export const FIELD_VALIDATION_INVALID_POST_CODE =
  'Invalid Postcode, only numbers, letters and spaces are allowed.';
export const FIELD_VALIDATION_INVALID_NAME = 'Please enter a valid name';
export const FIELD_VALIDATION_INVALID_EMAIL = 'Pleas enter a valid email';

const REGEX_FULL_NAME = /^[\p{Letter}\p{Mark}']+(((\s|-)[\p{Letter}\p{Mark}']+)+)?$/u;
const REGEX_ONLY_LETTERS = /^[a-zA-Z]+$/;
const REGEX_EMAIL = /^\w+([\.-]?\w+)*(\+\w+)?@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export const REQUIRED_EMAIL_RULES = requiredStringRule().matches(
  REGEX_EMAIL,
  FIELD_VALIDATION_INVALID_EMAIL,
);

export const OPTIONAL_STRING_RULES = yup.string().notRequired();
export const REQUIRED_STRING_RULES = requiredStringRule();

export const REQUIRED_ARRAY_RULES = yup.array().min(1, 'At least one value is required');

export const OPTIONAL_BOOLEAN_RULES = yup.bool().notRequired();
export const REQUIRED_BOOLEAN_RULES = yup.bool().oneOf([true], 'Required').required();

export const REQUIRED_POST_CODE_VALIDATION_RULES = requiredStringRule().matches(
  /^[0-9A-Za-z ]+$/,
  FIELD_VALIDATION_INVALID_POST_CODE,
);

export const REQUIRED_FULL_NAME_VALIDATION_RULES = requiredStringRule().matches(
  REGEX_FULL_NAME,
  FIELD_VALIDATION_INVALID_NAME,
);

export const OPTIONAL_FULL_NAME_VALIDATION_RULES = optionalRule(
  requiredStringRule().matches(REGEX_FULL_NAME, FIELD_VALIDATION_INVALID_NAME),
);

export const REQUIRED_ONLY_LETTERS_VALIDATION_RULES = requiredStringRule().matches(
  REGEX_ONLY_LETTERS,
  'Only letters allowed',
);

export const REQUIRED_DRIVER_LICENCE_NUMBER_VALIDATION_RULES = requiredStringRule({
  min: 6,
  max: 10,
});

export const REQUIRED_PASSPORT_VALIDATION_RULES = requiredStringRule({ min: 5, max: 15 });

export const REQUIRED_EXPIRY_DATE_VALIDATION_RULES = requiredDateRule({
  min: subDays(new Date(), 1), // To make sure the min date `new Date()` works as expected
});

export const MIN_BIRTH_OF_DATE_GAP = 365 * 120; //  120 years old
export const REQUIRED_DATE_OF_BIRTH_VALIDATION_RULES = requiredDateRule({
  min: subDays(new Date(), MIN_BIRTH_OF_DATE_GAP + 1),
  max: new Date(),
});

function optionalRule(basicRule: yup.Schema): any {
  return yup.lazy<yup.Schema>((value) => {
    if (!!value) {
      return basicRule;
    }
    return yup.mixed().notRequired();
  });
}

export const fileRule = (requiredErrorMessage: string) => {
  return yup.object().shape({
    file: yup
      .object()
      .required(requiredErrorMessage)
      .test('fileSize', 'The maximum file size allowed is 10MB', (value: any) => {
        const { file }: { file: File } = value;
        // Maximum allowed file size is 10MB
        if (file?.size > 10 * 1000 * 1000) {
          return false;
        }
        return true;
      })
      .test('fileType', 'Please choose only pdf, jpeg or png file', (value: any) => {
        const { file }: { file: File } = value;
        if (['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'].includes(file?.type)) {
          return true;
        }
        return false;
      }),
  });
};

export const optionalFileRule: any = (requiredErrorMessage: string) => {
  return optionalRule(fileRule(requiredErrorMessage));
};

/**
 *
 * If need to check exactly same length, just make min equals max, namely `min === max`
 *
 * @param options
 * `min`: the minimum length of number, default is `1`
 *
 *  `max`: the maximum length of number, default is `Number.MAX_VALUE`
 */
function requiredRule({
  min,
  max,
  dataType,
  requiredMessage = 'Required',
}: TValidationConstraints & TRequiredMessage) {
  let basicRule = yup.string().trim().required(requiredMessage);

  let showType = 'characters';

  if (dataType === 'number') {
    showType = 'digits';
    basicRule = basicRule.matches(/^[0-9]+$/, `Must be only ${showType}`);
  }

  let minMessage = `Must be at least ${min} ${showType}`;
  let maxMessage = `Must be at most ${max} ${showType}`;

  if (min === max) {
    minMessage = `Must be exactly ${min} ${showType}`;
    maxMessage = `Must be exactly ${max} ${showType}`;
  }
  return basicRule.min(min, minMessage).max(max, maxMessage);
}

export function requiredNumberRule({
  min = defaultLengthConstraints.min,
  max = defaultLengthConstraints.max,
}: TLengthConstraints = defaultLengthConstraints) {
  return requiredRule({ min, max, dataType: 'number' });
}

export function optionalNumberRule({
  min = defaultLengthConstraints.min,
  max = defaultLengthConstraints.max,
}: TLengthConstraints = defaultLengthConstraints) {
  return optionalRule(requiredNumberRule({ min, max }));
}

export function requiredStringRule({
  min = defaultLengthConstraints.min,
  max = defaultLengthConstraints.max,
  requiredMessage,
}: Partial<TLengthConstraints> & TRequiredMessage = defaultLengthConstraints) {
  return requiredRule({ min, max, dataType: 'string', requiredMessage });
}

export function optionalStringRule({
  min = defaultLengthConstraints.min,
  max = defaultLengthConstraints.max,
}: TLengthConstraints = defaultLengthConstraints) {
  return optionalRule(requiredStringRule({ min, max }));
}

export function requiredDateRule({ min, max }: { min?: Date; max?: Date } = {}) {
  let basicRule = yup
    .date()
    .transform(parseDateString)
    .typeError('Please enter a valid date')
    .required(FIELD_VALIDATION_REQUIRED);

  if (min && isValid(min)) {
    basicRule = basicRule.min(min, `Min date should be ${format(min, 'dd/MM/yyyy')}`);
  }
  if (max && isValid(max)) {
    basicRule = basicRule.max(max, `Max date should be ${format(max, 'dd/MM/yyyy')}`);
  }
  return basicRule;
}

export function nonApplicableTextInputRule(message?: string) {
  return yup.string().max(0, message ?? 'Not applicable');
}

const forCountryOfIssueLabel = (countryCode: SupportedCountryCode) => {
  const countryLabel = SUPPORTED_COUNTRY_MAP[countryCode]?.label;
  return countryLabel ? `for ${countryLabel}` : '';
};

export function countryStateSelectInputRule(countryCode: SupportedCountryCode) {
  if (!Object.keys(COUNTRY_STATES).includes(countryCode)) {
    return nonApplicableCountryStateSelectInputRule(countryCode);
  }

  type TCountryCodeWithState = keyof typeof COUNTRY_STATES;
  const stateCodes = COUNTRY_STATES[countryCode as TCountryCodeWithState].map(
    (option) => option.code,
  );
  const countryLabel = forCountryOfIssueLabel(countryCode);
  return yup
    .string()
    .oneOf(stateCodes, `State must be one of state ${countryLabel}`)
    .required(`State is required ${countryLabel}`);
}

function nonApplicableCountryStateSelectInputRule(countryCode: SupportedCountryCode) {
  const countryLabel = forCountryOfIssueLabel(countryCode) ?? '';
  return yup.string().oneOf([''], `State is not applicable ${countryLabel}`);
}
