import { Theme } from '@mui/material';
import { alpha } from '@mui/material/styles';

export type TColorName =
  | 'successMain'
  | 'warningDark'
  | 'errorMain'
  | 'extreme'
  | 'textDisabled'
  | 'verifiedIdentity'
  | 'rejectedIdentity'
  | 'draftIdentity'
  | 'unverifiedIdentity'
  | 'infoDark'
  | 'actionActive'
  | 'verificationPending';
export type TIconName =
  | 'checkCircle'
  | 'block'
  | 'remove'
  | 'pending'
  | 'closeFullscreenRounded'
  | 'checkCircleRounded'
  | 'warningRounded'
  | 'cancelRounded'
  | 'blockRounded'
  | 'radioButtonUncheckedRounded'
  | 'removeCircleRounded'
  | 'errorRounded'
  | 'keyboardArrowDownRounded'
  | 'deleteRounded'
  | 'warningAmberRounded'
  | 'addRounded'
  | 'closeRounded'
  | 'editRounded'
  | 'verifiedRounded'
  | 'arrowRightRounded'
  | 'arrowLeftRounded'
  | 'LockRounded'
  | 'emailRounded'
  | 'moreVertRounded'
  | 'autoAwesomeRounded'
  | 'reviewsRounded'
  | 'fileCopyRounded'
  | 'uploadFileIcon'
  | 'imageNotSupportedRounded'
  | 'openInNewRounded'
  | 'pdfRounded'
  | 'fileDownloadRounded';

export const COLOR_MAPPING: {
  [colorName in TColorName]: (theme: Theme) => {
    textColor: string;
    fillColor?: string;
    borderColor?: string;
    iconColor?: string;
  };
} = {
  successMain: (theme) => ({
    textColor: theme.palette.success.contrastText,
    fillColor: theme.palette.success.main,
  }),
  warningDark: (theme) => ({
    textColor: theme.palette.warning.contrastText,
    fillColor: theme.palette.warning.dark,
  }),
  errorMain: (theme) => ({
    textColor: theme.palette.error.contrastText,
    fillColor: theme.palette.error.main,
  }),
  extreme: (theme) => ({
    textColor: theme.palette.error.contrastText,
    fillColor: theme.palette.grey[800],
    borderColor: theme.palette.text.primary,
  }),
  textDisabled: (theme) => ({
    textColor: theme.palette.error.contrastText,
    fillColor: theme.palette.text.disabled,
  }),
  verifiedIdentity: (theme) => ({
    textColor: theme.palette.success.contrastText,
    fillColor: theme.palette.success.main,
    iconColor: theme.palette.success.contrastText,
  }),
  rejectedIdentity: (theme) => ({
    textColor: theme.palette.error.contrastText,
    fillColor: theme.palette.error.main,
    iconColor: theme.palette.success.contrastText,
  }),
  draftIdentity: (theme) => ({
    textColor: theme.palette.error.contrastText,
    fillColor: theme.palette.text.disabled,
    iconColor: alpha(theme.palette.success.contrastText, 0.5),
  }),
  unverifiedIdentity: (theme) => ({
    textColor: theme.palette.error.contrastText,
    fillColor: theme.palette.text.disabled,
    iconColor: theme.palette.success.contrastText,
  }),
  infoDark: (theme) => ({
    textColor: theme.palette.info.dark,
    borderColor: theme.palette.info.dark,
  }),
  actionActive: (theme) => ({
    textColor: theme.palette.action.active,
    fillColor: theme.palette.action.active,
    iconColor: alpha(theme.palette.action.active, 0.5),
  }),
  verificationPending: (theme) => ({
    textColor: theme.palette.text.disabled,
    fillColor: theme.palette.background.paper,
    borderColor: theme.palette.action.disabled,
  }),
};

export function getColorByName(colorName: TColorName) {
  return COLOR_MAPPING[colorName];
}
