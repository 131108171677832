import {
  Stack,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Typography,
  Link,
  useTheme,
} from '@mui/material';
import { LoginCenterContainer, LoginContainer } from '../common/LoginContainer';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import React from 'react';
import tokens from '@verifime/design-tokens';
import { SetupState } from '../../types';
import { LEARN_MORE_AUTHENTICATOR_URL, LEARN_MORE_PASSKEY_URL } from '@verifime/utils';
import TermsOfUseLink from '../../TermsOfUseLink';
import PrivacyPolicyLink from '../../PrivacyPolicyLink';

export type TSetupYourAccountProps = {
  setSetupState?: (setupState: SetupState) => void;
  otpUserDisplayName?: string;
};

const SetupItem = ({
  primary,
  secondary,
  onClick,
}: {
  primary: React.ReactNode;
  secondary: React.ReactNode;
  onClick: () => void;
}) => (
  <ListItem disablePadding>
    <ListItemButton
      sx={{
        borderRadius: tokens.borderRadiusSm,
        border: (theme) => `1px solid ${theme.palette.primary.light}`,
      }}
      onClick={onClick}
    >
      <ListItemText primary={primary} secondary={secondary} />
      <ListItemIcon
        sx={{
          padding: tokens.spacingSm,
        }}
      >
        <ChevronRightRoundedIcon />
      </ListItemIcon>
    </ListItemButton>
  </ListItem>
);

const LearnMoreLink = ({ href }: { href: string }) => (
  <Link href={href} target="_blank" onClick={(e) => e.stopPropagation()}>
    Learn more
  </Link>
);

export default function SetupYourAccount({
  setSetupState,
  otpUserDisplayName,
}: TSetupYourAccountProps) {
  const handleSetupAuthenticatorApp = () => {
    setSetupState(SetupState.TOTP);
  };

  const handleSetupPasskey = () => {
    setSetupState(SetupState.FIDO_STEP2);
  };

  const theme = useTheme();

  return (
    <LoginContainer
      sx={{
        p: `${tokens.spacingXl} ${tokens.spacingBase}`,
        gap: tokens.spacingBase,
      }}
    >
      <LoginCenterContainer gap={tokens.spacingSm}>
        <Typography variant="h5" align="center" width={tokens.sizeXs}>
          Setup your VerifiMe account
        </Typography>
        {otpUserDisplayName && (
          <Typography variant="h6" sx={{ mt: tokens.spacingBase }}>
            {otpUserDisplayName}
          </Typography>
        )}
        <Typography variant="body1" sx={{ mt: tokens.spacingLg }}>
          VerifiMe uses password-less authentication. Select how you'll access your account.
        </Typography>

        <Stack gap={tokens.spacingBase} sx={{ mt: tokens.spacingLg }}>
          <SetupItem
            primary={<Typography variant="body1">Trust this device?</Typography>}
            secondary={
              <Typography variant="body2" color={theme.palette.text.secondary}>
                Log in quickly and securely using this device's fingerprint/face recognition or PIN.{' '}
                <LearnMoreLink href={LEARN_MORE_PASSKEY_URL} />
              </Typography>
            }
            onClick={handleSetupPasskey}
          />
          <SetupItem
            primary={<Typography variant="body1">Set up an authenticator</Typography>}
            secondary={
              <Typography variant="body2" color={theme.palette.text.secondary}>
                Get a unique code and link each time you login with Google Authenticator, Authy etc.{' '}
                <LearnMoreLink href={LEARN_MORE_AUTHENTICATOR_URL} />
              </Typography>
            }
            onClick={handleSetupAuthenticatorApp}
          />
        </Stack>

        <Typography variant="caption">
          Please contact support@verifime.com if you need help
        </Typography>
      </LoginCenterContainer>
    </LoginContainer>
  );
}
