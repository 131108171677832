import { Button, Stack } from '@mui/material';
import {
  Configuration,
  DocumentReaderApi,
  GraphicFieldType,
  Scenario,
} from '@regulaforensics/document-reader-webclient';
import {
  DocumentReaderDetailType,
  DocumentReaderResponseType,
  DocumentReaderWebComponent,
  DocumentReaderService,
  IDocumentReader,
} from '@regulaforensics/vp-frontend-document-components';
import { useEffect, useRef, useState } from 'react';

import { DetailedHTMLProps, HTMLAttributes } from 'react';

declare global {
  interface Window {
    RegulaDocumentSDK: DocumentReaderService;
  }

  namespace JSX {
    interface IntrinsicElements {
      'document-reader': DetailedHTMLProps<
        IDocumentReader & HTMLAttributes<DocumentReaderWebComponent>,
        DocumentReaderWebComponent
      >;
    }
  }
}

const configuration = new Configuration({ basePath: 'https://nightly-api.regulaforensics.com' });
const api = new DocumentReaderApi(configuration);

export default function DocumentReader({
  license,
  onSuccess,
}: Readonly<{
  license?: string;
  onSuccess?: (response: DocumentReaderResponseType) => void;
}>) {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const isInitializationPerformed = useRef(false);
  const elementRef = useRef<DocumentReaderWebComponent>(null);

  const listener = async (data: CustomEvent<DocumentReaderDetailType>) => {
    if (data.detail.action === 'PROCESS_FINISHED') {
      const status = data.detail.data?.status;
      // Success
      if (status === 1) {
        const componentResponse = data.detail.data.response;

        onSuccess?.(componentResponse);

        const imageField = componentResponse.images.getField(GraphicFieldType.DOCUMENT_FRONT);
        const documentFront = imageField.valueList[1].value;
        const processParam = {
          images: [documentFront],
          processParam: {
            scenario: Scenario.FULL_PROCESS,
          },
        };

        const result = await api.process(processParam);

        setTimeout(() => setIsOpen(false), 1000);
      }
    }

    if (data.detail?.action === 'CLOSE') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isInitializationPerformed.current) {
      return;
    }

    const initDocumentReaderService = async () => {
      isInitializationPerformed.current = true;
      const { defineComponents, DocumentReaderService } = await import(
        '@regulaforensics/vp-frontend-document-components'
      );

      window.RegulaDocumentSDK = new DocumentReaderService();
      window.RegulaDocumentSDK.recognizerProcessParam = {
        processParam: {
          returnUncroppedImage: true,
          scenario: 'MrzOrLocate',
          multipageProcessing: false,
          returnPackageForReprocess: false,
          timeout: 20000,
          resultTypeOutput: [],
          imageQa: {
            expectedPass: ['dpiThreshold', 'glaresCheck', 'focusCheck'],
            dpiThreshold: 300,
            glaresCheck: true,
            glaresCheckParams: {
              imgMarginPart: 0.05,
              maxGlaringPart: 0.01,
            },
          },
        },
      };

      // To use the document-reader component on test environments, you have to set the base64 license
      if (license) {
        defineComponents().then(() => window.RegulaDocumentSDK.initialize({ license }));
      } else {
        // Production environment
        defineComponents().then(() => window.RegulaDocumentSDK.initialize());
      }
    };

    initDocumentReaderService();

    const containerCurrent = containerRef.current;

    if (!containerCurrent) {
      return;
    }

    containerCurrent.addEventListener('document-reader', listener);

    return () => {
      if (!window.RegulaDocumentSDK) {
        return;
      }

      window.RegulaDocumentSDK.shutdown();

      containerCurrent.removeEventListener('document-reader', listener);
    };
  }, []);

  useEffect(() => {
    const elementRefCurrent = elementRef.current;

    if (!elementRefCurrent) {
      return;
    }

    elementRefCurrent.settings = {
      startScreen: true,
      changeCameraButton: true,
    };
  }, [isOpen]);

  return (
    <Stack ref={containerRef}>
      {isOpen ? (
        <document-reader ref={elementRef}></document-reader>
      ) : (
        <Button variant="contained" onClick={() => setIsOpen(true)}>
          Open Document Reader
        </Button>
      )}
    </Stack>
  );
}
