import { Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';

export default function DocumentNotFound({ text = 'No Image Found' }: { text?: string }) {
  return (
    <Stack
      width={tokens.size2xs}
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: (theme) => theme.palette.divider,
        borderRadius: tokens.borderRadiusSm,
        aspectRatio: '25/16',
      }}
    >
      <Typography variant="body1">{text}</Typography>
    </Stack>
  );
}
