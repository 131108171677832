import { Stack } from '@mui/material';
import tokens from '@verifime/design-tokens';
import { ColoredIcon } from './ColoredIconAndChip';

export default function DocumentNotSupported() {
  return (
    <Stack
      width={tokens.size2xs}
      justifyContent="center"
      alignItems="center"
      sx={{
        backgroundColor: (theme) => theme.palette.divider,
        borderRadius: tokens.borderRadiusSm,
        aspectRatio: '25/16',
      }}
    >
      <ColoredIcon iconName="imageNotSupportedRounded" colorName="textDisabled" />
    </Stack>
  );
}
