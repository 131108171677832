import CloseIcon from '@mui/icons-material/CloseRounded';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import SvgIcon from '@mui/material/SvgIcon';
import Toolbar from '@mui/material/Toolbar';

import Link from 'next/link';

import { useTheme } from '@mui/material';
import Logo from '../Logo';

import tokens from '@verifime/design-tokens';
import { useRouter } from 'next/router';
import React from 'react';
import { TNavItem } from '../types';

import { styled } from '@mui/material/styles';
import ToggleColorMode from './ToggleColorMode';

const VerifimeStyledMenu = styled((props: MenuProps) => (
  <Menu
    MenuListProps={{
      'aria-labelledby': 'lock-button',
      role: 'listbox',
    }}
    keepMounted
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    width: '100vw',
    maxWidth: '100vw',
    left: '0 !important',
    '& .MuiMenuItem-root': {
      width: '100%',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
}));

export default function TopBarV2({
  navItems,
  signOut,
  homeHref,
}: {
  navItems: TNavItem[];
  signOut: () => void;
  homeHref?: string;
}) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const appBarRef = React.useRef<HTMLElement>();

  const open = Boolean(anchorEl);
  const router = useRouter();
  const handleMenuClick = () => {
    setAnchorEl(appBarRef.current);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [activeIdx, setActiveIdx] = React.useState(0);

  const MediumLogo = () => <Logo colorMode={theme.palette.mode} size="medium" />;

  React.useEffect(() => {
    if (navItems.length < 1) {
      return;
    }
    const activeIdx = navItems.findIndex((item) => item.path === router.pathname) || 0;
    setActiveIdx(activeIdx);
  }, [router.pathname, navItems]);

  return (
    <AppBar
      position="static"
      ref={appBarRef}
      sx={{
        backgroundColor: theme.palette.background.paper,
      }}
      elevation={8}
    >
      <Toolbar disableGutters>
        <IconButton
          size="large"
          aria-label="Menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenuClick}
        >
          {open ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
        <VerifimeStyledMenu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          slotProps={{
            paper: {
              elevation: 8,
            },
          }}
        >
          <MenuList>
            {navItems.map((sideNavItem, index) => {
              return sideNavItem.isDivider === true ? (
                <Divider key={index} />
              ) : (
                <MenuItem key={index} selected={activeIdx === index}>
                  <ListItemButton
                    LinkComponent={Link}
                    key={sideNavItem.label}
                    href={sideNavItem.path}
                    onClick={handleMenuClose}
                  >
                    <ListItemIcon>
                      <SvgIcon component={sideNavItem.icon} />
                    </ListItemIcon>
                    <ListItemText primary={sideNavItem.label} />
                  </ListItemButton>
                </MenuItem>
              );
            })}
            <MenuItem>
              <ListItemButton LinkComponent={Link} key="Logout" href="#" onClick={signOut}>
                <ListItemIcon>
                  <SvgIcon component={LogoutIcon} />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </ListItemButton>
            </MenuItem>
            <MenuItem>
              <ToggleColorMode />
            </MenuItem>
          </MenuList>
        </VerifimeStyledMenu>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingY: tokens.spacingBase,
            paddingX: tokens.spacingLg,
          }}
        >
          {homeHref ? (
            <Link href={homeHref}>
              <MediumLogo />
            </Link>
          ) : (
            <MediumLogo />
          )}
        </Box>

        <Button
          size="medium"
          variant="text"
          sx={{
            py: tokens.spacingXs,
            px: tokens.spacingBase,
          }}
          onClick={signOut}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}
