import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import tokens from '@verifime/design-tokens';
import React from 'react';
import Accessories from './accessories.svg';
import ButtonIcon from './button.svg';
import CameraLevel from './cameraLevel.svg';
import Illumination from './illumination.svg';

export default function StartScreen({ onButtonClick }: Readonly<{ onButtonClick: () => void }>) {
  return (
    <Paper>
      <Stack
        gap={tokens.spacingBase}
        alignItems="center"
        maxWidth={tokens.sizeSm}
        paddingY={tokens.spacing2lg}
        paddingX={tokens.spacingBase}
      >
        <Typography variant="h5">Ready for a face check?</Typography>
        <Stack gap={tokens.spacingXs} paddingY={0} paddingX={tokens.spacingBase}>
          <Stack direction="row" gap={tokens.spacingBase} alignItems="center">
            <Stack
              width={tokens.spacing5xl}
              height={tokens.spacing4xl}
              justifyContent="center"
              alignItems="center"
            >
              <Illumination />
            </Stack>
            <Typography variant="body1">Make sure the lighting is good</Typography>
          </Stack>
          <Stack direction="row" gap={tokens.spacingBase} alignItems="center">
            <Box width={tokens.spacing5xl}>
              <Accessories />
            </Box>
            <Typography variant="body1">Keep the camera at eye level.</Typography>
          </Stack>
          <Stack direction="row" gap={tokens.spacingBase} alignItems="center">
            <Box width={tokens.spacing5xl}>
              <CameraLevel />
            </Box>
            <Typography variant="body1">
              Avoid wearing accessories like glasses, masks, hats, etc.
            </Typography>
          </Stack>
        </Stack>
        <Button startIcon={<ButtonIcon />} variant="contained" onClick={onButtonClick}>
          Complete Face Check
        </Button>
      </Stack>
    </Paper>
  );
}
